import { ISocketUtilityStore } from '@/interfaces/modules/ISocketUtilityStore'
import { defineStore, Pinia } from 'pinia'
import main from '@/main'
import { useInstrumentDataStore } from '@/stores/data/InstrumentDataStore'
import { PricePoint } from '@/types'
import { useUserDataStore } from '../data/UserDataStore'
import { useOrderDataStore } from '../data/OrderDataStore'
import { useMarketDataStore } from '../data/MarketDataStore'

export const useSocketUtilityStore = defineStore({
	id: 'socketUtility',
	state: (): ISocketUtilityStore => ({
		isConnected: false,
		message: '',
		lastSentMessage: undefined,
		reconnectError: false,

		liquidation: 0,
		margin: 0,
		leverage: 0,
		eventId: '',
	}),
	actions: {
		connect(jwt: string) {
			if (this.isConnected && !main.config.globalProperties.$connect) return
			document.cookie = `accessToken=${jwt}`
			main.config.globalProperties.$connect()
		},
		disconnect() {
			if (!this.isConnected) return
			main.config.globalProperties.$disconnect()
		},
		send(message: string | object) {
			this.lastSentMessage = message

			if (typeof message === 'string') {
				this.sendString(message)
			} else if (typeof message === 'object') {
				this.sendObj(message)
			}
		},
		sendObj(message: object) {
			this.send(JSON.stringify(message))
		},
		sendString(message: string) {
			main.config.globalProperties.$socket.send(message)
		},
		SOCKET_ONOPEN(event: Event) {
			main.config.globalProperties.$socket = event.currentTarget
			this.isConnected = true
		},
		SOCKET_ONCLOSE() {
			this.isConnected = false
		},
		SOCKET_ONERROR(event: Event) {
			console.error(event)
		},   
		SOCKET_ONMESSAGE(message: MessageEvent) {
			const { payload, eventType, eventId} = JSON.parse(message.data)
			const instrumentStore = useInstrumentDataStore()
			const marketStore = useMarketDataStore()
			const userStore = useUserDataStore()
			const orderStore = useOrderDataStore()
    
			switch (eventType) {
			case 'price-update':
				instrumentStore.updateInstrumentPricePoint(payload.instrumentId, payload as PricePoint)
				marketStore.updateInstrumentPricePoint(payload.instrumentId, payload as PricePoint)
				break
			case 'portfolio-orders':
				orderStore.updatePortfolio(payload)
				break
			case 'portfolio-orders-update':
				orderStore.updatePortfolioOrder(payload)
				break
			case 'user-data':
				userStore.updateUser(payload)
				break
			case 'wallet-update':
				console.log('wallet update:', payload)
				break
			case 'compute-liquidation-margin':
				this.liquidation = payload?.liquidation ?? 0
				this.margin = payload?.margin ?? 0
				this.eventId = eventId
				break
			case 'compute-order-update':
				this.liquidation = payload?.liquidation ?? 0
				this.leverage = payload?.leverage ?? 0
				this.eventId = eventId
				break
			default: 
				console.log('Received WS message:', message.data)
			}

			this.message = message.data
		},
		SOCKET_RECONNECT(count: Event) {
			console.info('Message system reconnecting...', count)
		},
		SOCKET_RECONNECT_ERROR() {
			this.reconnectError = true
		},
	},
})

export function useSocketUtilityStoreWithOut(store: Pinia) {
	return useSocketUtilityStore(store)
}