
import { defineComponent } from 'vue'
import Button from '@/components/General/Button.vue'

export default defineComponent({
	name: 'PublicTopBar',
	components: {
		Button,
	},
})
