
import { defineComponent } from 'vue'
import Menu from '@/layouts/appLayout/Menu.vue'
import Summary from '@/layouts/appLayout/Summary.vue'
import { RouteNames, GtmCategory, GtmEvents } from '@/enums'
import { useAppDataStore } from '@/stores/data/AppDataStore'
import { useUserDataStore } from '@/stores/data/UserDataStore'
import { gtmPush } from '@/helpers/gtm'

export default defineComponent({
	name: 'SideBar',
	components: {
		Menu,
		Summary,
	},
	data() {
		return {
			RouteNames,
			GtmCategory,
			GtmEvents,
			appStore: useAppDataStore(),
			userStore: useUserDataStore(),
			gtmPush,
		}
	},
})
