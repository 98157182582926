import { MasksHelper } from '@/types'
import { MaskaDetail } from 'maska'
import filters from './filters'
import { Currency, CurrencySymbol } from '@/enums'

const masks: MasksHelper = {
	price: {
		onMaska: (detail: MaskaDetail) => detail,
		preProcess: (val: string) => val.replace(/[$,]/g, ''),
		postProcess: (val: string) => {
			if (!val) return ''

			// const sub = 3 - (val.includes('.') ? val.length - val.indexOf('.') : 0)
    
			return val
		},
		mask: '0.9999999999',
		tokens: {
			'0': {
				pattern: /\d/,
				multiple: true,
			},
			'9': {
				pattern: /\d/,
				optional: true,
			},
		},
	},
	USD: {
		onMaska: (detail: MaskaDetail) => detail,
		preProcess: (val: string) => val.replace(/[$,]/g, ''),
		postProcess: (val: string) => {
			if (!val) return ''

			const sub = 3 - (val.includes('.') ? val.length - val.indexOf('.') : 0)
    
			return filters.price(Number(val), Currency.USD, 2).slice(0, sub ? -sub : undefined)
		},
		mask: '0.99',
		tokens: {
			'0': {
				pattern: /\d/,
				multiple: true,
			},
			'9': {
				pattern: /\d/,
				optional: true,
			},
		},
	},
	EUR: {
		onMaska: (detail: MaskaDetail) => detail,
		preProcess: (val: string) => val.replace(/[$,]/g, ''),
		postProcess: (val: string) => {
			if (!val) return ''

			const sub = 3 - (val.includes('.') ? val.length - val.indexOf('.') : 0)
    
			return filters.price(Number(val), Currency.EUR, 2).slice(0, sub ? -sub : undefined)
		},
		mask: '0.99',
		tokens: {
			'0': {
				pattern: /\d/,
				multiple: true,
			},
			'9': {
				pattern: /\d/,
				optional: true,
			},
		},
	},
	

	digits: {
		mask: '#',
		tokens: {
			'#': {
				pattern: /\d/,
				multiple: true,
			},
		},
	},
	decimal: {
		mask: '0.99999999999999',
		tokens: {
			'0': {
				pattern: /\d/,
				multiple: true,
			},
			'9': {
				pattern: /\d/,
				optional: true,
			},
		},
	},
}

export default masks