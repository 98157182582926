import { AxiosError } from 'axios'
import { useToast } from 'vue-toastification'
import { useAppDataStore } from '@/stores/data/AppDataStore'

const toast = useToast()

export function handleAxios(error: AxiosError) {
	const appStore = useAppDataStore()
	if (typeof error.code !== 'undefined') {
		if (error.code === 'ERR_BAD_REQUEST') {
			const errorMessage = (error.response?.data as any)?.message
			if (errorMessage) {
				if(appStore.i18n().te(`errors.${errorMessage}`)) {
					toast.error(appStore.i18n().t(`errors.${errorMessage}`))
				} else {
					toast.error(errorMessage)
				}
			}
		}
	}
}