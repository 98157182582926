export default {
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vše"])},
  "account": {
    "changePassword": {
      "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím potvrďte nové heslo"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změna hesla"])},
      "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte prosím nové heslo"])},
      "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte prosím staré heslo"])}
    },
    "deleteAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat účet"])},
    "emailTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svoji e-mailovou adresu můžete změnit ve vašem profilu."])},
    "FAQ": {
      "items": [
        {
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jsem nově v obchodní platformě ", _interpolate(_named("brand")), ". Kde je nejlepší začít?"])},
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Začátek vaší cesty s ", _interpolate(_named("brand")), " začíná vytvořením účtu, jednoduchým procesem, který vám zabere jen pár minut. Protože ", _interpolate(_named("brand")), " výhradně obchoduje s kryptoměnami a tokeny, využíváme služby partnerů třetí strany, kteří nabízejí služby na zjednodušení procesu přihlášení pro naše zákazníky. Verifikace Na závěr musí uživatelé ", _interpolate(_named("brand")), " projít procesem ověření totožnosti a poskytnout důkazy o zdroji svých prostředků v souladu s předpisy AML. Verifikační proces je rychlý a jednoduchý, a my vám během něj rádi pomůžeme."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jak si mohu vytvořit účet?"])},
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pro vytvoření účtu ", _interpolate(_named("brand")), " postupujte podle těchto kroků: 1. Navštivte naši webovou stránku a v pravém horním rohu vyberte možnost „Otevřít účet“. 2. Zadejte své jméno a e-mailovou adresu a vyberte silné heslo. 3. Pokud souhlasíte, potvrďte obchodní podmínky a zásady ochrany osobních údajů. 4. Pokračujte tím, že kliknete na tlačítko „Vytvořit účet“. 5. K dokončení nastavení účtu se přihlaste."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jaké služby nabízí ", _interpolate(_named("brand")), "?"])},
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("brand")), " nabízí různé služby, které jsou především zaměřeny na provádění obchodů s tokenizovanými aktivy a poskytování komplexních tržních dat a doplňkových informací, jako jsou články, finanční údaje, výzkum a doporučení analytiků."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaké druhy aktiv jsou k dispozici k obchodování?"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naše platforma nabízí výhradně obchodování s tokenizovanými aktivy, včetně akcií, kryptoměn, forexu, indexů, komodit a ETF."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jaké jsou hlavní výhody investování prostřednictvím ", _interpolate(_named("brand")), "?"])},
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tokenizované aktivity od ", _interpolate(_named("brand")), " představují průlomový způsob, jak se zapojit do cenových fluktuací akcií, forexu, komodit, indexů, kryptoměn nebo ETF. Tato inovace vám umožňuje investovat do aktiv s pákovým efektem nebo jako částečné vlastnictví, a to bez provizí a s úzkými spready, kdykoliv vám to vyhovuje. Například, máte zájem o zakoupení tokenizovaných akcií Google v hodnotě 10 000 EUR? Nyní to můžete udělat s pouhými 100 EUR díky pákovému efektu 100x a stále obdržíte dividendy."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jak funguje tokenizace?"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokenizované aktivity jsou tokeny navržené tak, aby zrcadlily hodnotu konkrétních aktiv, jako jsou kryptoměny, drahé kovy nebo jiná základní aktiva. Pokud jde o investiční výsledky, ať už jde o zisk nebo ztrátu, mají podobnosti s akciemi, měnami, komoditami a různými jinými finančními nástroji. V současné době je obchodování k dispozici pro různé tokenizované burzovně obchodované aktivity, včetně tokenizovaných akcií různých společností jako Google a Facebook, komodit jako zlato a ropa a indexů jako S&P 500 a DAX 40. Když zakoupíte tokenizované akcie, ve skutečnosti nezískáváte fyzické akcie. Například AAPL.b představuje tokenizovaný burzovně obchodovaný aktiv, který zrcadlí vývoj akcií Apple na burze Nasdaq. Investoři mohou zakoupit tokeny AAPL.b a obchodovat s nimi podobně jako s akciemi Apple, včetně možnosti obdržení dividend."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostávám dividendové platby za svou \"long\" pozici na akciích?"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ano, pokud udržujete \"long\" pozici v tokenizovaných akciích, máte nárok na dividendy, které reprodukují výhody spojené s držením skutečných akcií."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaká kritéria bych měl použít k určení, do kterého aktiva investovat?"])},
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Občas může být volba správného aktiva náročným rozhodnutím. Proto poskytujeme doplňkové informace pro každé aktivum. Na ", _interpolate(_named("brand")), " můžete získat profily společností, hodnocení analytiků, výkonnostní ukazatele a různé klíčové výkonnostní ukazatele, které vám pomohou při rozhodování. Pro další informace nás kontaktujte prostřednictvím zákaznické podpory. Je důležité si uvědomit, že podobně jako u jiných tříd aktiv ", _interpolate(_named("brand")), " neposkytuje žádné investiční rady."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jak se uplatňuje poplatek za držení pozice přes noc (swap)?"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pákové tokenizované pozice, které jsou drženy přes noc, jsou podléhány malému poplatku, který se vypočítává podle hodnoty pozice. Tyto poplatky jsou ovlivněny dynamikou nabídky a poptávky na finančních trzích a pomáhají kryt poplatků spojených s vaší pozicí. V podstatě slouží jako úroková platba, která má za cíl kompenzovat náklady na páku, kterou využíváte během noci. Poplatky za víkend jsou naopak poplatky, které se uplatňují, když jsou pozice drženy otevřené během víkendu. Například: Při zahájení pákové operace na nákup 10 akcií tokenizovaných AAPL za cenu 200 USD.b: ● Objem operace činí: 10 * 200 = 2 000 USD.b ● Poplatek činí: 2 000 * 0,05 % = 1 USD.b Stejně tak při otevírání pákové operace na nákup 1 000 USD.b hodnoty tokenizovaných EUR/USD za cenu 1,05000 USD.b s pákovým efektem 20: ● Objem operace činí: 1 000 * 20 = 20 000 USD.b ● Poplatek činí: 20 000 * 0,05 % = 10 USD.b"])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co znamená izolovaný margin?"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izolovaný margin představuje funkci řízení rizik používanou na naší obchodní platformě. Jedná se o obchodní režim, ve kterém obchodníci mohou využít pouze určenou část svého celkového účetního zůstatku k zahájení a udržení pozic v různých aktivech. Hlavní funkcí izolovaného obchodování na margin je schopnost umožnit obchodníkům omezit potenciální ztráty na kapitál, který byl určen pouze pro konkrétní obchod, a tím ochránit zbývající prostředky na jejich obchodním účtu."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Proč ", _interpolate(_named("brand")), " používá Bitcoin jako svůj \"motor\"?"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro jeho nezávislost, transparentnost a bezpečnost. Věříme, že finance a investice by měly přijímat inkluzivní přístup, a to je přesně to, co kryptoměny dokážou. Například: Rychlost a dostupnost: Transakce s Bitcoiny mohou probíhat rychle, obvykle během několika minut, bez ohledu na geografické hranice nebo bankovní hodiny. Tato dostupnost je zvláště výhodná pro mezinárodní obchod a převody peněz. Minimální transakční náklady: Transakce s Bitcoiny obvykle zahrnují nižší poplatky ve srovnání s konvenčními bankovními nebo platebními metodami, zejména pro mezinárodní převody. Tím se stávají atraktivní volbou pro přeshraniční transakce a malé transakce. Bezpečnost a transparentnost: Transakce s Bitcoiny jsou zabezpečeny kryptografickými opatřeními a zaznamenány v veřejném účetním systému známém jako blockchain. Tento transparentní a neupravitelný účetní systém poskytuje silný pocit bezpečí a důvěry v systém. Zvyšování finanční dostupnosti: Bitcoin slibuje rozšíření přístupu k finančním službám pro osoby bez bankovního účtu a s omezeným bankovním účtem po celém světě. Ti, kteří nemají klasický přístup k bankovnictví, mohou jednoduše zapojit do globální ekonomiky pouhým využitím Bitcoinu, k němuž potřebují pouze připojení k internetu a smartphone. Inovace a flexibilita: Bitcoinova všestrannost vyvolala inovativní aplikace. Původně koncipován jako digitální měna, proměnil se v uchovatele hodnoty a zajištění proti inflaci, což zaujalo zájem institucionálních investorů a přeformovalo konvenční finančnictví. Tato přizpůsobivost zdůrazňuje schopnost Bitcoinu neustále inovovat jako reakci na rostoucí finanční potřeby. Celosvětové přijetí: Bitcoin je postupně přijímán stále více podniky"])}
        }
      ],
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Často kladené dotazy"])}
    },
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jazyk"])},
    "limits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limity"])},
    "manageYourAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Správa účtu"])},
    "manageYourEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změnit e-mailovou adresu"])},
    "manageYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změnit heslo"])},
    "menu": {
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měna"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámení"])},
      "personalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osobní údaje"])},
      "referral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenční kód"])},
      "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezpečnost"])},
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpora"])},
      "verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověření"])},
      "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peněženka"])},
      "trading-academy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investiční akademie"])}
    },
    "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odběr novinek"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámení"])},
    "notificationsHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vždy, když něco nového přibude do kanálu novinek"])},
    "personalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osobní údaje"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpora"])},
    "supportDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napište nám, pokud máte nějaký problém."])},
    "supportForm": {
      "inputName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpráva"])}
    },
    "supportSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše zpráva byla odeslána."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])}
  },
  "auth": {
    "changePassword": {
      "bannerDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovte heslo snadno a zabezpečte svúj účet."])},
      "bannerHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovit heslo"])},
      "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzení hesla"])},
      "confirmPasswordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím, potvrďte nové heslo znovu"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změnit heslo"])},
      "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové heslo"])},
      "newPasswordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím, zadejte nové heslo"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit"])}
    },
    "general": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailová adresa"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonní číslo"])},
      "goToEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jít do emailu"])},
      "linkHasBeenSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzovací odkaz byl zaslán na Váš email"])},
      "noVerificationEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepřišel Vám ověřovací email?"])},
      "openEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otevřít email"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo"])},
      "resendVerificationEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znovu odeslat ověřovací email"])},
      "returnToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět na přihlášení"])},
      "countryOfResidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Země pobytu"])}
    },
    "resetPassword": {
      "bannerDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovení hesla je snadné, zabezpečte Vaše obchody."])},
      "bannerHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovte své heslo"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovte své heslo"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požádat o obnovu hesla"])}
    },
    "signIn": {
      "bannerDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příležitosti čekají na každého"])},
      "bannerHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Těší nás, že Vás vidíme zpět"])},
      "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapomněli jste heslo?"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlášení"])},
      "noAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemáte ještě účet?"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlásit se"])}
    },
    "signUp": {
      "alreadyHaveAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Již máte účet"])},
      "bannerDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A začněte investovat během několika minut"])},
      "bannerHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otevřete si svůj účet nyní"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrace"])},
      "iAgreeTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhlasím s "])},
      "iAgreeWithSNP": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Souhlasím s ", _interpolate(_named("brand")), " obchodními podmínkami a ochranou osobních údajů"])},
      "iAgreeWithTnC": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Souhlasím s ", _interpolate(_named("brand")), " obchodními podmínkami"])},
      "IHaveReadSNP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přečetl jsem si podmínky služby a soukromí"])},
      "privacyPolicy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("brand")), " Zásady ochrany osobních údajů"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit účet"])},
      "successfullyRegistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš účet byl úspěšně založen. Prosím přihlašte se."])},
      "termsCond": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("brand")), " smluvních podmínek"])},
      "termsCondRead": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Přečetl jsem si ", _interpolate(_named("brand")), " smluvních podmínek"])},
      "passwordHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jak na silné heslo"])},
      "passwordHelpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silné heslo by mělo obsahovat:"])},
      "passwordRequirements": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délka: ≥ 12 znaků"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velká a malá písmena (A-Z)(a-z), čísla (0-9) a symboly (!#&#64;$%^&*)"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepoužívejte znovu hesla a vyhněte se běžným slovům nebo osobním informacím."])}
      ]
    },
    "verify": {
      "bannerDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokončete ověření profilu pro bezproblémové obchody"])},
      "bannerHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokončete nastavení profilu"])},
      "firstInstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6místný ověřovací kód byl odeslán na váš e-mail:"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřte svůj účet"])},
      "invalidCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřovací kód je neplatný nebo expiroval, máte ještě 3 pokusy"])},
      "invalidCodeFinal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřovací kód je neplatný nebo expiroval, zkuste to znovu za 1 hodinu"])},
      "secondInstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte ověřovací kód pro aktivaci vašeho účtu."])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivovat účet"])},
      "verificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřovací kód"])}
    }
  },
  "countries": {
    "are": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spojené arabské emiráty"])},
    "arg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonésie"])},
    "aus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austrálie"])},
    "aut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rakousko"])},
    "bhr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahrajn"])},
    "blr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bělorusko"])},
    "bra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brazílie"])},
    "can": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanada"])},
    "che": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Švýcarsko"])},
    "chl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chile"])},
    "chn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čína"])},
    "col": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolumbie"])},
    "cze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Česká republika"])},
    "deu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Německo"])},
    "dnk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dánsko"])},
    "ecu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekvádor"])},
    "esp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Španělsko"])},
    "est": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estonsko"])},
    "fin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finsko"])},
    "fra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francie"])},
    "gbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spojené království"])},
    "grc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Řecko"])},
    "hkg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hongkong"])},
    "hrv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chorvatsko"])},
    "hun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maďarsko"])},
    "idn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonésie"])},
    "ind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indie"])},
    "irl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irsko"])},
    "isl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Island"])},
    "isr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izrael"])},
    "ita": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itálie"])},
    "jor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jordánsko"])},
    "jpn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japonsko"])},
    "kaz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazachstán"])},
    "kor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jižní Korea"])},
    "kwt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuvajt"])},
    "mex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mexiko"])},
    "mys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malajsie"])},
    "nld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nizozemsko"])},
    "nor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norsko"])},
    "nzl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nový Zéland"])},
    "omn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omán"])},
    "per": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peru"])},
    "phl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filipíny"])},
    "pol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polsko"])},
    "prt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugalsko"])},
    "qat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katar"])},
    "rou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rumunsko"])},
    "rus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rusko"])},
    "rwa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rwanda"])},
    "sau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saúdská Arábie"])},
    "sgp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapur"])},
    "srb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Srbsko"])},
    "svk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovensko"])},
    "svn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovinsko"])},
    "swe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Švédsko"])},
    "tha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thajsko"])},
    "tur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turecko"])},
    "twn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tchaj-wan"])},
    "ukr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukrajina"])},
    "usa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spojené státy americké"])},
    "vnm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnam"])},
    "zaf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jihoafrická republika"])}
  },
  "cta": {
    "completeVerificationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro možnost obchodování nejdříve dokončete ověření totožnosti."])},
    "completeVerificationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokončete ověření"])},
    "readyToStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připraveni začít?"])},
    "readyToStartButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vložit peníze"])},
    "readyToStartDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přibližte se o krok blíže k vašim ziskům."])},
    "readyToVerify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připraveni začít obchodovat?"])},
    "readyToVerifyButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřit teď"])},
    "readyToVerifyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolujte svůj e-mail a dokončete proces ověření!"])}
  },
  "currencies": {
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vše"])},
    "btc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BTC"])},
    "eur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR"])},
    "usd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USD"])}
  },
  "dashboard": {
    "commodities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komodity"])},
    "cryptocurrencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kryptoměny"])},
    "etfs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETF"])},
    "forex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forex"])},
    "indices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indexy"])},
    "noInstruments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné instrumenty"])},
    "stocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akcie"])},
    "trendingNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trendy"])},
    "watchlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oblíbené"])}
  },
  "errors": {
    "wrongPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše původní heslo je špatné."])},
    "24hRemainingLimit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["24h zbývající limit ", _interpolate(_named("limit")), " BTC"])},
    "allowedFileTypes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Můžete nahrát pouze tyto typy souborů: ", _interpolate(_named("allowedFileTypes")), "."])},
    "depositError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Něco se pokazilo, zkuste to prosím později znovu"])},
    "depositSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše žádost o vklad byla úspěšně odeslána"])},
    "fieldMaximumSat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maximálně 10 000 SAT"])},
    "fieldMinimumBtc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minimum 0,001 BTC"])},
    "instrumentNotAvaible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nástroj této objednávky již není k dispozici."])},
    "MARKET_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trh je v současné době uzavřen. Zkuste to prosím později."])},
    "LEVERAGE_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neplatná páka. Zadejte prosím platnou hodnotu."])},
    "PRICE_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neplatná cena. Zadejte prosím platnou hodnotu."])},
    "STOP_LOSS_PRICE_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neplatná cena Stop Lossu."])},
    "TAKE_PROFIT_PRICE_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neplatná cena Take Profitu."])},
    "QUANTITY_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neplatné množství. Zadejte prosím platnou hodnotu."])},
    "INSTRUMENT_DISABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento instrument je v současné době vypnut. Zkuste to prosím později."])},
    "ORDER_NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozice nebyla nalezena. Zkontrolujte prosím ID pozice a zkuste to znovu."])},
    "order not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozice nebyla nalezena. Zkontrolujte prosím ID pozice a zkuste to znovu."])},
    "ORDER_ALREADY_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato pozice je již uzavřena. Zkuste to prosím znovu s novým příkazem."])},
    "QUANTITY_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Množství je příliš nízké. Zadejte prosím vyšší hodnotu."])},
    "QUANTITY_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Množství je příliš vysoké. Zadejte nižší hodnotu."])},
    "QUANTITY_PRECISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesprávné desetinné číslo u množství. Zadejte prosím platnou hodnotu."])},
    "MARGIN_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marže je příliš vysoká. Zadejte prosím nižší hodnotu."])},
    "MARGIN_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marže je příliš nízká. Zadejte prosím vyšší hodnotu."])},
    "INSUFFICIENT_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedostatečný zůstatek. Prosím, doplňte na svůj účet finanční prostředky."])},
    "LEVERAGE_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanční páka je příliš nízká. Zadejte prosím vyšší hodnotu."])},
    "LEVERAGE_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanční páka je příliš vysoká. Zadejte prosím nižší hodnotu."])},
    "LIMIT_PRICE_MUST_BE_SMALLER_THAN_ASK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limitní cena musí být menší než aktuální poptávková cena. Zadejte prosím platnou hodnotu."])},
    "LIMIT_PRICE_MUST_BE_GREATER_THAN_BID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limitní cena musí být větší než aktuální nabídková cena. Zadejte prosím platnou hodnotu."])},
    "TAKE_PROFIT_PRICE_MUST_BE_GREATER_THAN_ASK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take Profit cena musí být vyšší než aktuální poptávková cena. Zadejte prosím platnou hodnotu."])},
    "TAKE_PROFIT_PRICE_MUST_BE_SMALLER_THAN_BID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take Profit cena musí být menší než aktuální nabídková cena. Zadejte prosím platnou hodnotu."])},
    "STOP_LOSS_PRICE_MUST_BE_GREATER_THAN_ASK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop Loss cena musí být vyšší než aktuální poptávková cena. Zadejte prosím platnou hodnotu."])},
    "STOP_LOSS_PRICE_MUST_BE_SMALLER_THAN_BID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop Loss cena musí být menší než aktuální nabídková cena (BID)."])},
    "STOP_LOSS_PRICE_MUST_BE_SMALLER_THAN_LIQUIDATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop Loss cena musí být menší než likvidační cena. Zadejte prosím platnou hodnotu."])},
    "STOP_LOSS_PRICE_MUST_BE_GREATER_THAN_LIQUIDATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop Loss cena musí být vyšší než likvidační cena. Zadejte prosím platnou hodnotu."])},
    "maxFiles": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Můžete nahrát pouze ", _interpolate(_named("numberOfFiles")), " souborů."])},
    "maxFileSize": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maximální povolená velikost souboru je ", _interpolate(_named("maxSize")), " MB."])},
    "while market is closed you can only open LIMIT orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Během zavřeného trhu můžete zadat pouze limitní objednávku."])},
    "withdrawError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Něco se pokazilo, zkuste to prosím později znovu"])},
    "withdrawSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše žádost o výběr byla úspěšně odeslána"])},
    "bankCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód banky není platný"])},
    "bankNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo bankovního účtu není platné"])},
    "invalidIban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN není platný"])},
    "expiredToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platnost tokenu vypršela."])},
    "unspecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Něco se pokazilo, zkuste to prosím později znovu"])},
    "invalidCombination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neplatná kombinace e-mailu a hesla"])},
    "invalidToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlášení se nezdařilo - chyba v tokenu"])},
    "tokenExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlášení se nezdařilo - token vypršel"])},
    "cantFetchPaymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nelze načíst platební metody."])},
    "somethingWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Něco se pokazilo."])}
  },
  "filters": {
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechno"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzavřené"])},
    "deposits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vklady"])},
    "filled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otevřené"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpracovává se"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpracovává se"])},
    "withdrawals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výběry"])},
    "interests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úroky"])}
  },
  "general": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Množství"])},
    "availableBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostupný zůstatek"])},
    "beginExchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začít směnu"])},
    "bitcoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitcoin"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koupit"])},
    "BUY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KOUPIT"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Město"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zavřít"])},
    "closedPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena uzavření"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Země"])},
    "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měna"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vklad"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
    "dropFileHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přetáhněte soubor sem."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailová adresa"])},
    "entryPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vstupní cena"])},
    "financialScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanční skóre"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Křestní jméno"])},
    "instrument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úrok"])},
    "interest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úroky"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jazyk"])},
    "liquidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Likvidace"])},
    "loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ztráta"])},
    "margin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marže"])},
    "marginUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Použitá marže"])},
    "markets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trhy"])},
    "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Střední"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přehled"])},
    "NA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepřichází v úvahu"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Síť"])},
    "noOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné objednávky"])},
    "openAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otevřít účet"])},
    "openPositions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otevřené pozice"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objednávky"])},
    "paginationText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("from")), " - ", _interpolate(_named("to")), " z ", _interpolate(_named("total")), " záznamů"])},
    "pAndL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zisk a ztráta"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonní číslo"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena"])},
    "profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zisk"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet"])},
    "recordsPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["záznamů na stránku"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hledat"])},
    "secondName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjmení"])},
    "sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sektor"])},
    "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Více"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodat"])},
    "SELL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRODAT"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulice"])},
    "tid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TID"])},
    "toAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na adresu"])},
    "topGainers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vítězové"])},
    "topLosers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poražení"])},
    "totalBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkový zůstatek"])},
    "totalPAndL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zisk a ztráta"])},
    "transactionHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historie transakcí"])},
    "trendingNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trendy"])},
    "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neomezeně"])},
    "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahrát soubor"])},
    "userId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID uživatele"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peněženka"])},
    "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrat"])},
    "withdrawal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výběr"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PSČ"])},
    "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Předpona"])},
    "bankNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo účtu"])},
    "bankCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo banky"])},
    "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
    "copiedToClipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkopírováno do schránky"])},
    "goToHomepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domovská stránka"])},
    "promoCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promo kód (nepovinné)"])},
    "securitySettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení zabezpečení"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět"])},
    "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Způsob platby"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopírovat"])},
    "contactSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktujte podporu"])}
  },
  "instruments": {
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změna"])},
    "instrument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument"])},
    "instruments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrumenty"])},
    "noInstruments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné instrumenty"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena"])}
  },
  "error404": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba 404"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omlouváme se, ale stránka, kterou hledáte, neexistuje."])}
  },
  "instrumentTypes": {
    "etf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETF"])},
    "crypto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto"])},
    "commodity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komodity"])},
    "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akcie"])},
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Index"])},
    "forex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forex"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vše"])}
  },
  "kyc": {
    "aml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dotazník o původu finančních prostředků"])},
    "amlDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na základě zákona č. 235/2008 Sb."])},
    "backSide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadní strana"])},
    "bannerDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřte osobní údaje nyní a ušetřete čas a peníze."])},
    "bannerHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jen pár kliknutí od obchodování"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obchod"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Město"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zavřít"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Země"])},
    "documentNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo dokumentu"])},
    "documentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ dokumentu"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotovo"])},
    "dueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termín"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "emailTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaši e-mailovou adresu můžete změnit v profilu."])},
    "employment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaměstnání"])},
    "firstAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$0 - $50K"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno"])},
    "fourthAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$251K a více"])},
    "frontSide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přední strana"])},
    "incomeAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš čistý roční příjem?"])},
    "investedAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaká je vaše očekávaná investovaná částka?"])},
    "leaveForLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponechat na později"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne"])},
    "noCriminalActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzujete, že peníze nejsou získány z kriminální aktivity, financování terorismu a že nemáte podnikání nebo jiné aktivity v zemích, proti nimž Česká republika uplatňuje mezinárodní sankce."])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jiné"])},
    "partnerForDeposits": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("brand")), " využívá k hotovostním vkladům platební službu btcex.cz"])},
    "personalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osobní údaje"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonní číslo"])},
    "politicalPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jste politicky exponovanou osobou?"])},
    "proofOfIdentity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doklad totožnosti"])},
    "proofOfIdentityDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahrajte dokument, který prokazuje vaši totožnost"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výsledek ověření vám bude zaslán e-mailem"])},
    "resultDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověření může trvat několik hodin"])},
    "saveAndContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit a pokračovat"])},
    "secondAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$51K - $100K"])},
    "secondName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjmení"])},
    "secondProofOfIdentity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druhý doklad totožnosti"])},
    "secondProofOfIdentityDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahrajte druhý doklad totožnosti (rodný list, pas, kartu pojištěnce)"])},
    "sourceOfIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaký je váš zdroj příjmu?"])},
    "startVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začít ověřování"])},
    "startWithVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začněte s ověřením osobních údajů"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulice"])},
    "thirdAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$101 - 250 Kč"])},
    "uploadFileHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahrajte dokument ve formátu .jpg, .png nebo .jpeg (max. 3 MB)"])},
    "verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověření"])},
    "verificationOfPersonalData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověření osobních údajů"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ano"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PSČ"])}
  },
  "lightningAuth": {
    "general": {
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo"])},
      "signInCta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlásit se pomocí Lightning"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelské jméno (může být e-mail)"])}
    },
    "resetPassword": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovte své heslo"])},
      "returnToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět na přihlášení"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyžádat obnovení hesla"])}
    },
    "signIn": {
      "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemáte účet"])},
      "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapoměli jste heslo?"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlásit se"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlásit se"])}
    },
    "signUp": {
      "alreadyOwnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Již máte účet"])},
      "descriptionFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pouze jeden formulář k prvním ziskům!"])},
      "descriptionSecond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oceňujeme a respektujeme vaše soukromí"])},
      "heading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vytvořte svůj ", _interpolate(_named("brand")), " účet"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit účet"])}
    }
  },
  "menu": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet"])},
    "account-support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpora"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přehled"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhlásit se"])},
    "markets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trhy"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpora"])},
    "trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obchodovat"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peněženka"])},
    "account-security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zabezpečení"])},
    "account-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení"])},
    "account-change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změnit heslo"])},
    "portfolio-myorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje objednávky"])},
    "trading-academy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investiční akademie"])}
  },
  "platform": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loft"])}
  },
  "portfolio": {
    "30DaysPAndL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zisk a ztráta za 30 dní"])},
    "assetAllocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alokace aktiv"])},
    "forLast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Za posledních ", _interpolate(_named("days")), " dní"])},
    "fromClosedOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z uzavřených objednávek"])},
    "myOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje objednávky"])},
    "noOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné objednávky"])},
    "pAndLAnalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analýza zisku a ztráty"])},
    "yesterdaysPAndL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zisk a ztráta ze včerejška"])},
    "swapFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swapový poplatek"])},
    "dividend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dividenda"])}
  },
  "publicTrade": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nás"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začít investovat"])},
    "generalInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obecné informace"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupujte a prodávejte zlomky akcií již od €1"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začněte svou investiční cestu ještě dnes"])}
  },
  "router": {
    "checked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemůžete pokračovat bez souhlasu"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vklad"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapomenuté heslo?"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historie"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domů"])},
    "kyc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověření totožnosti"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlásit se"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhlásit se"])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hodnota může být nejvýše ", _interpolate(_named("max")), " znaků dlouhá"])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hodnota musí být alespoň ", _interpolate(_named("min")), " znaků dlouhá"])},
    "myOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje objednávky"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrovat se"])},
    "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovit heslo"])},
    "sameAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnoty musí být stejné"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení"])},
    "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výběr"])}
  },
  "sectors": {
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vše"])},
    "automotive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automobilový průmysl"])},
    "commodity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komodita"])},
    "communication services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komunikační služby"])},
    "consumer cyclical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotřebitelský cyklus"])},
    "consumer defensive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obrana spotřebitele"])},
    "crypto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krypto"])},
    "etf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETF"])},
    "financial services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanční služby"])},
    "forex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forex"])},
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Index"])},
    "communications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telekomunikace"])},
    "consumerDiscretionary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotřebitelské zboží a služby"])},
    "consumerStaples": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základní konzumní zboží"])},
    "energy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energie"])},
    "financials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])},
    "healthcare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdravotnictví"])},
    "industrials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průmyslový sektor"])},
    "materials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materiály"])},
    "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatní"])},
    "realEstate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realitní trh"])},
    "technology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technologie"])},
    "utilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energetika"])}
  },
  "success": {
    "orderClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše objednávka byla úspěšně uzavřena"])},
    "orderCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše objednávka byla úspěšně provedena"])},
    "orderUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše objednávka byla úspěšně aktualizována"])},
    "langChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jazyk aplikace byl úspěšně změněn"])},
    "passwordChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše heslo bylo změněno."])}
  },
  "time": {
    "april": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duben"])},
    "august": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Srpen"])},
    "december": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosinec"])},
    "february": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Únor"])},
    "fridayShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pá"])},
    "january": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leden"])},
    "july": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Červenec"])},
    "june": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Červen"])},
    "march": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Březen"])},
    "may": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Květen"])},
    "mondayShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po"])},
    "november": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listopad"])},
    "october": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Říjen"])},
    "saturdayShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So"])},
    "september": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Září"])},
    "sundayShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne"])},
    "thursdayShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čt"])},
    "tuesdayShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Út"])},
    "userId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID uživatele"])},
    "wednesdayShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["St"])}
  },
  "trade": {
    "tradeingViewLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sledujte všechny trhy na TradingView"])},
    "closedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzavřeno v"])},
    "closeOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zavřít pozici"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořeno v"])},
    "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit"])},
    "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trh"])},
    "marketClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trh uzavřen"])},
    "orderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID objednávky"])},
    "stopLoss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realizovat ztrátu při"])},
    "swapFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap poplatek"])},
    "dividend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dividenda"])},
    "takeProfit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realizovat zisk při"])},
    "performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výkonnost"])},
    "recommendationTrends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trendy doporučení"])},
    "recommendationRating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratingové doporučení"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádná aktuální data"])},
    "notAccessible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba, tato stránka není přístupná."])},
    "generalInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obecné informace"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sektor"])},
    "marketCap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tržní omezení"])},
    "52high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["52W vysoká"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Země"])},
    "avgVol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prům. Vol."])},
    "52low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["52W nejnižší"])}
  },
  "transaction": {
    "states": {
      "ABORTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušeno"])},
      "APPROVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schváleno"])},
      "CANCELED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušeno"])},
      "COMPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokončeno"])},
      "CREATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořeno"])},
      "CREDITED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připsáno"])},
      "EXCHANGE_PROCESSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Směna provedena"])},
      "EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expirováno"])},
      "FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokončeno"])},
      "HEDGED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zajištěno"])},
      "PAYMENT_APPROVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba schválena"])},
      "PAYMENT_RECEIVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijatá platba"])},
      "PAYMENT_TO_RETURN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrácení platby"])},
      "PENDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpracovává se"])},
      "UNDER_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřováno"])},
      "UNSUPPORTED_STATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepodporovaný stav"])},
      "WAITING_FOR_APPROVAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čeká na schválení"])}
    }
  },
  "typesOfDocuments": {
    "drivingLicense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Řidičský průkaz"])},
    "idCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Občanský průkaz"])},
    "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas"])}
  },
  "validators": {
    "nonAlpha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnota může obsahovat pouze číslice"])},
    "alpha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnota může obsahovat pouze písmena"])},
    "alphaNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnota může obsahovat pouze písmena a číslice"])},
    "between": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hodnota musí být mezi ", _interpolate(_named("min")), " a ", _interpolate(_named("max"))])},
    "checked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemůžete pokračovat bez souhlasu s tímto"])},
    "disabledInstrument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento nástroj je deaktivován"])},
    "dividableBy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Množství musí být násobkem ", _interpolate(_named("step")), "."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte platnou e-mailovou adresu"])},
    "greaterThanBid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musí být větší nebo rovno prodejní ceně"])},
    "insufficientBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedostatečný zůstatek"])},
    "leverageNotAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato páka není pro tento nástroj povolena"])},
    "limitOrdersNotAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limitní objednávky nejsou pro tento nástroj povoleny"])},
    "lowerThanAsk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musí být menší nebo rovno nákupní ceně"])},
    "marginMaxValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Marže nesmí být větší než ", _interpolate(_named("max"))])},
    "marginMinValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Marže musí být alespoň ", _interpolate(_named("min"))])},
    "maxValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hodnota nesmí přesáhnout ", _interpolate(_named("max"))])},
    "minLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text je příliš krátký"])},
    "maxLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text je příliš dlouhý"])},
    "minValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hodnota musí být alespoň ", _interpolate(_named("value"))])},
    "minLeverage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Minimální povolená páka je ", _interpolate(_named("min")), ", snižte prosím marži."])},
    "maxLeverage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maximální povolená páka je ", _interpolate(_named("max")), ", zvyšte prosím marži."])},
    "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnota může obsahovat pouze číslice"])},
    "phoneNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte platné telefonní číslo"])},
    "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte platné PSČ"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toto pole je povinné"])},
    "requiredIf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toto pole je povinné"])},
    "stopLossBuy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musí být větší než likvidační cena a menší než aktuální nákupní cena"])},
    "stopLossSell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musí být menší než likvidační cena a větší než aktuální prodejní cena"])},
    "takeProfitBuy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musí být větší nebo rovno aktuálnímu nákupnímu cenovému limitu"])},
    "takeProfitSell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musí být menší nebo rovno aktuálnímu prodejnímu cenovému limitu"])},
    "diffAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové heslo se musí lišit od starého hesla"])},
    "sameAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzení hesla není stejné jako nové heslo"])},
    "notSufficientBalance": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zůstatek na vašem účtu není dostatečný pro marži ", _interpolate(_named("model")), "!"])},
    "liquidationBuy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Snížení marže na ", _interpolate(_named("model")), " vyvolá likvidaci pozice!"])},
    "liquidationSell": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Snížení marže na ", _interpolate(_named("model")), " vyvolá likvidaci pozice!"])},
    "lowerMarginMarketClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marži nelze snížit, dokud je trh uzavřen."])}
  },
  "wallet": {
    "badges": {
      "fastest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejrychlejší"])},
      "verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověření"])},
      "cheapest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejlevnější"])}
    },
    "conversionRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Míra konverze"])},
    "conversionDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upozorňujeme, že výše zobrazená částka a konečná připsaná částka se mohou lišit. Většinou je ovlivněna řadou faktorů, jako je rychlost dokončení, KYC, směnné poplatky a směnný kurz."])},
    "fundUsingBitcoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vklad na obchodní účet převodem Bitcoinu"])},
    "exchanges": {
      "CONVERTER_BTCEXCZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BTCex.cz"])},
      "BITCOIN_ONCHAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitcoin"])},
      "CREDIT_CARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditní karta"])}
    },
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa peněženky"])},
    "bitcoinNetwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitcoinová síť"])},
    "amountError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka není platná"])},
    "bankAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankovní účet"])},
    "bankNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo bankovního účtu"])},
    "bitcoinOnChain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitcoin"])},
    "chooseCurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte měnu"])},
    "beneficiaryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno příjemce"])},
    "beneficiaryBankAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa banky příjemce"])},
    "beneficiaryBankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název banky příjemce"])},
    "beneficiaryBankSwift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SWIFT"])},
    "depositAnimation": {
      "firstSentence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koruny posíláte na účet Btcx.cz vedený u české banky"])},
      "fourthSentence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitcoin se smění na Eura a ty putují na Váš obchodní učet."])},
      "secondSentence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Díky Btcex.cz provedete nákup Bitcoinu."])},
      "thirdSentence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitcoin slouží jako rychlé transakční medium."])}
    },
    "depositBannerDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A nezmeškejte příležitosti"])},
    "depositBannerHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vložte své<br>peníze nyní"])},
    "depositLightningDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vklad bude okamžitě připsán na váš účet."])},
    "depositOnchainDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na tuto adresu zasílejte <strong>pouze BTC</strong>.<br> Tato <strong>adresa</strong> nepodporuje vklady jiných sítí.<br> Všechny <strong>vklady pod minimální hodnotu budou ztraceny</strong> . Neposílejte na tuto adresu NFTčka."])},
    "depositSteps": {
      "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslání prostředků do banky"])},
      "step2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nákup BTC přes ", _interpolate(_named("provider")), " směnu"])},
      "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslání BTC brokerovi"])},
      "step4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výměna Bitcoinů za Eura"])},
      "step5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připsání na účet klienta"])}
    },
    "approx": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cca. ", _interpolate(_named("currency")), " ", _interpolate(_named("value"))])},
    "depositToAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vložte na svůj obchodní účet"])},
    "expectedArrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Očekávané datum připsání"])},
    "fiatCzkDisclaimner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka v CZK se může změnit v závislosti na aktuálním směnném kurzu CZK/EUR"])},
    "fiatDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pro vklady používá ", _interpolate(_named("brand")), " partnera btcex.cz"])},
    "fiatDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posíláte české koruny na účet v české bance, který drží společnost Btcex.cz."])},
    "fiatEurDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka v EUR se může změnit v závislosti na aktuálním směnném kurzu CZK/EUR"])},
    "fiatWithdrawalLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit výběru Fiat"])},
    "floatingRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zde uvedený konverzní kurz je odhad. Skutečná částka, kterou obdržíte, bude určena v době zaúčtování platby."])},
    "minimalWithdrawalEquivalentOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimální výběr je ekvivalentní"])},
    "generatePaymentInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vygenerovat platební informace"])},
    "iAmBuyingViaBtcex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nakupuji přes btcex.cz"])},
    "infoMaximum": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["max. ", _interpolate(_named("amount")), " ", _interpolate(_named("currency"))])},
    "infoMaximumBtc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. 0,1 BTC"])},
    "infoMinimum": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["min. ", _interpolate(_named("amount")), " ", _interpolate(_named("currency"))])},
    "infoMinimumBtc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min. 0,001 BTC"])},
    "infoMinimumCzk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min. 1 000 CZK"])},
    "infoMinimumEur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min. 50 EUR"])},
    "lightningBitcoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitcoin Lightning"])},
    "lightningFeature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bleskově rychlé a diskrétní!"])},
    "maximumFiatDepositLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximální limit fiat vkladu"])},
    "messageForRecipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpráva pro příjemce"])},
    "methods": {
      "fiat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CZK EUR"])},
      "lightning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitcoin Lightning"])},
      "onchain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitcoin"])}
    },
    "minimumDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimální vklad"])},
    "minimumFiatDepositLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimální limit pro uložení fiat"])},
    "minimumDepositEquivalentOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimální vklad je ekvivalentní"])},
    "networkConfirmations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("confirmations")), " potvrzení v síti"])},
    "networkFee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fee")), " poplatek sítě BTC zahrnut"])},
    "networkTransactionFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poplatek sítě"])},
    "noTransactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné transakce"])},
    "checkoutWithBanxa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokladna s Banxa"])},
    "minDeposit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Min. vklad ", _interpolate(_named("amount"))])},
    "maxDeposit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Max. vklad ", _interpolate(_named("amount"))])},
    "banxaFees": {
      "surcharge": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Poplatek brány ", _interpolate(_named("amount"))])}
    },
    "onchain": {
      "descriptionWithdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte zůstatek z obchodního účtu do své bitcoinové peněženky"])},
      "step1": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřte a odešlete žádost o výběr uživatelem."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spustit výběr"])}
      },
      "step2": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení a potvrzení transakce ze strany Loftu."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavit transakci "])}
      },
      "step3": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakce byla odeslána do sítě."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat transakci"])}
      }
    },
    "fiat": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bankovní převod"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vklad bankovním převodem a směnárnou třetí strany Btcex.cz"])},
      "descriptionWithdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento způsob výběru zahrnuje manuální ověření a může trvat až 3 pracovní dny v závislosti na zvolené měně."])}
    },
    "creditCardMethod": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditní karta / Bankovní převod"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okamžitý vklad přes Banxa "])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba proběhla úspěšně"])},
      "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba selhala"])},
      "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nevyřízená platba"])},
      "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba zrušena"])}
    },
    "creditCardMethods": {
      "APPLE_PAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apple Pay"])},
      "VISA_MASTERCARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa/Mastercard"])},
      "GOOGLE_PAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Pay"])}
    },
    "paymentAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka platby"])},
    "paymentInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platební informace"])},
    "platformTransactionFee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Poplatek za transakci ", _interpolate(_named("name"))])},
    "qrPaymentInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR platba"])},
    "recieveAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijatá částka"])},
    "selectMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte způsob vkladu"])},
    "selectWithdrawalMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento způsob výběru zahrnuje manuální ověření a může trvat až 3 pracovní dny (v závislosti na zvolené měně)."])},
    "settledDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po provedení bankvního převodu bude na váš obhcodní účet připsán kredit v EUR."])},
    "settledWithdrawal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezpečně převedeme peníze na váš účet nebo krypto-peněženku."])},
    "creditCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditní karta / Bankovní převod"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nový"])},
    "3dSecure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okamžitý vklad přes Banxa s pomocí kreditních i debetních karet, bankovních převodů, Apple Pay, Google Pay nebo jiných platebních metod."])},
    "bankTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bankovní převod"])},
    "fiatDeposits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vklad bankovním převodem a směnárnou třetí strany Btcex.cz"])},
    "bitcoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bitcoin"])},
    "fundingBitcoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vklad na obchodní účet převodem Bitcoinu"])},
    "transactionFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poplatek za transakci"])},
    "transferIDShortcut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TID"])},
    "variableSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variabilní symbol"])},
    "willBeSentToBankAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bude odesláno na vaš účet v bance"])},
    "withdrawalAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka k výběru"])},
    "withdrawalInvoiceCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód faktury"])},
    "withdrawalSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shrnutí výběru"])},
    "withdrawalNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A je hotovo. BTCex.cz Vás bude v nejbližší době informovat o vyřízení Vaší transakce. Pokud e-mail nedorazí, zkontrolujte prosím složku se spamem nebo kontaktujte podporu."])},
    "withdrawalNoteBtc": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Je možné, že vás bude kontaktovat ", _interpolate(_named("brand")), " za účelem potvrzení žádosti o výběr."])},
    "withdrawAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka výběru"])},
    "withdrawBannerDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmeškáte příležitosti, které právě probíhají"])},
    "withdrawBannerHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jste si jisti,<br>že chcete vybrat?"])},
    "withdrawLightningDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výběr je okamžitě připsán na váš účet."])},
    "withdrawPreparing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připravuje se výběr ... "])},
    "whatComesNext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["co bude dál?"])},
    "withdrawSteps": {
      "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výběr EUR z obchodního účtu"])},
      "step2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Připsání ", _interpolate(_named("currency")), " na klientský bankovní účet"])},
      "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nakonec BTCex.cz připíše hotovost na vámi zadaný bankovní účet."])},
      "step1btc": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jakýkoli výběr finančních prostředků podléhá bezpečnostnímu schválení ", _interpolate(_named("brand")), "."])},
      "step2btc": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jakmile bude vaše žádost schválena, ", _interpolate(_named("brand")), " pošle peníze na uvedenou adresu peněženky."])},
      "step3btc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nakonec bude převod zpracován na blockchainu a transakce vám budou připsány."])}
    },
    "toAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na účet"])},
    "fiatWithdrawalDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro výběr prostředků, musí být číslo účtu stejné jako u prvního vkladu, pokud bude číslo účtu jiné, hrozí zamítnutí ze strany partnera btcex.cz."])},
    "youWillRecieve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obdržíte"])},
    "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zřeknutí se odpovědnosti"])},
    "banxaDisclaimer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nyní opouštíte ", _interpolate(_named("brand")), " do <a href='https://banxa.com' class='hover:underline font-bold' target='_blank'>Banxa</a>. Služby související s platbami kartou poskytuje Banxa, samostatná platforma třetí strany. Pokračováním a využitím služeb Banxa potvrzujete, že jste si přečetli <a href='https://banxa.com/wp-content/uploads/2024/01/Customer-Terms-and-Conditions-22-January-2024-BANXA.pdf' class='hover:underline font-bold' target='_blank'>Podmínky použití Banxa</a> a <a href='https://banxa.com/wp-content/uploads/2024/03/Privacy-and-Cookies-Policy-3-March-2024-Banxa.pdf' class='hover:underline font-bold' target='_blank'>Zásady ochrany osobních údajů a souborů cookie</a>a souhlasíte s nimi. Máte-li jakékoli dotazy týkající se služeb Banxa, kontaktujte prosím Banxa na adrese <a href='https://support.banxa.com/' class='hover:underline font-bold' target='_blank'>support.banxa.com</a>."])},
    "agreeToDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přečetl jsem si prohlášení o vyloučení odpovědnosti a souhlasím s ním."])},
    "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Způsob platby"])},
    "amountPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka vyplacená"])},
    "transactionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID transakce"])},
    "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emailová adresa"])},
    "amoutLimits": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Částka by měla být mezi ", _interpolate(_named("min")), " ", _interpolate(_named("currency")), " a ", _interpolate(_named("max")), " ", _interpolate(_named("currency")), "."])},
    "orderNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objednávka nenalezena"])},
    "orderNotFoundDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenašli jsme objednávku, kterou hledáte."])},
    "checkoutWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vklad s"])},
    "orderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["číslo objednávky"])},
    "banxaAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vezměte prosím na vědomí, že nakupujete Bitcoiny na Banxa. Bitcoin bude převeden na měnu obchodního účtu."])},
    "depositUnavailable": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vklad nelze provést"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud se domníváte, že se jedná o chybu, kontaktujte náš zákaznickou podporu. Omlouváme se za nepříjemnosti."])}
    },
    "selectCurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte měnu výběru"])},
    "selectAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte částku"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upozorňujeme, že výše zobrazená částka a konečná připsaná částka se mohou lišit. Výsledná připsaná částka je ovlivněna řadou faktorů, jako je rychlost dokončení KYC, poplatky za transakci a směnný kurz."])},
    "accountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo účtu"])}
  }
}