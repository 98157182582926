import { defineStore } from 'pinia'
import axios, { AxiosResponse, AxiosError } from 'axios'
import { IInstrumentDataStore } from '@/interfaces'
import { Instrument, InstrumentDto, PricePoint } from '@/types'
import { computePriceChangeInPercents } from '@/helpers/number'

export const useInstrumentDataStore = defineStore({
	id: 'instrumentData',
	state: (): IInstrumentDataStore => ({
		instruments: new Map(),
		popularInstruments: new Map(),
		ratings: [],
		losers: [],
		gainers: [],
	}),

	getters: {
		getInstrumentById: (state) => {
			return (id: number) => state.instruments.get(id)
		},
		indicesInstruments: (state) => {
			return [...state.instruments.values()].filter((instrument) => {
				return instrument.instrumentType === 'INDEX'
			})
		},
		commoditiesInstruments: (state) => {
			return [...state.instruments.values()].filter((instrument) => {
				return instrument.instrumentType === 'COMMODITY'
			})
		},
		cryptoInstruments: (state) => {
			return [...state.instruments.values()].filter((instrument) => {
				return instrument.instrumentType === 'CRYPTO'
			})
		},
		etfInstruments: (state) => {
			return [...state.instruments.values()].filter((instrument) => {
				return instrument.instrumentType === 'ETF'
			})
		},
		forexInstruments: (state) => {
			return [...state.instruments.values()].filter((instrument) => {
				return instrument.instrumentType === 'FOREX'
			})
		},
		stocksInstruments: (state) => {
			return [...state.instruments.values()].filter((instrument) => {
				return instrument.instrumentType === 'STOCK'
			})
		},

	},

	actions: {
		async getInstrumentQuery(query: Array<number>): Promise<Array<Instrument>> {
			return await axios
				.get('instrument/query', {
					params: {
						ids: query.join(','),
					},
				})
				.then((response: AxiosResponse) => {
					return response.data
				})
				.catch((error: AxiosError) => {
					console.error(error)
				})
		},
		async fetchInstruments() {
			await axios
				.get('instrument')
				.then((response: AxiosResponse) => {
					// Instruments
					response.data.instruments.map((instrument: InstrumentDto) => {
						instrument.pricePoint.bid = Number.parseFloat(instrument.pricePoint.bid.toFixed(instrument.quotePrecision))
						instrument.pricePoint.ask = Number.parseFloat(instrument.pricePoint.ask.toFixed(instrument.quotePrecision))
						instrument.pricePoint.lastPrice = Number.parseFloat(instrument.pricePoint.lastPrice.toFixed(instrument.quotePrecision))
						const change = {
							price: instrument.pricePoint.lastPrice - instrument.lastClosePrice,
							percent: instrument.priceChange,
						}

						this.instruments.set(instrument.id, { ...instrument, change })
					})

					// Popular instruments
					const popularInstruments = response.data.popular_instruments.map((id: number) => response.data.instruments.find((instrument: Instrument) => instrument.id === id)) ?? []

					popularInstruments.forEach((instrument: Instrument) => {
						if (!instrument) return
						const change = {
							price: instrument.pricePoint.lastPrice - instrument.lastClosePrice,
							percent: instrument.priceChange,
						}

						this.popularInstruments.set(instrument.id, { ...instrument, change })
					})
				})
				.catch((error: AxiosError) => {
					console.error(error)
				})
		},
		updateInstrumentPricePoint(id: number, pricePoint: PricePoint) {
			const instrument = this.instruments.get(id)
			if (instrument) {
				instrument.pricePoint = {
					bid: Number.parseFloat(pricePoint.bid.toFixed(instrument.quotePrecision)),
					ask: Number.parseFloat(pricePoint.ask.toFixed(instrument.quotePrecision)),
					instrumentId: pricePoint.instrumentId,
					lastPrice: Number.parseFloat(pricePoint.lastPrice.toFixed(instrument.quotePrecision)),
					symbol: pricePoint.symbol,
					timestamp: pricePoint.timestamp,
				}
				this.instruments.set(id, instrument)
			}
		},
		async fetchTrends(instrumentId: number) {
			await axios
				.get(`instrument/${instrumentId}/ratings`)
				.then((response: AxiosResponse) => {
					this.ratings = response.data
				})
				.catch((error: AxiosError) => {
					console.error(error)
				})
		},
		async fetchLeaders() {
			await axios
				.get('instrument/top')
				.then((response: AxiosResponse) => {
					this.losers = response.data.losers
					this.gainers = response.data.gainers
				})
				.catch((error: AxiosError) => {
					console.error(error)
				})
		},
	},
})

