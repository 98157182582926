import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center justify-center h-full px-4 min-h-full" }
const _hoisted_2 = { class: "text-2xl sm:text-4xl md:text-5xl lg:text-6xl text-white font-semibold text-center" }
const _hoisted_3 = { class: "text-center text-white text-xl md:text-3xl max-w-lg pt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    class: "rounded-3xl bg-cover bg-center bg-no-repeat h-[90%] min-h-fit md:h-2/3 fixed inset-0 m-auto w-[90%] shadow-2xl",
    style: _normalizeStyle({backgroundImage: `url(${require('@/assets/img/graphics/404-background.jpg')})`})
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('error404.title')), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('error404.description')), 1),
      _createVNode(_component_Button, {
        class: "mt-10",
        to: "/",
        color: "primary"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('general.goToHomepage')), 1)
        ]),
        _: 1
      })
    ])
  ], 4))
}