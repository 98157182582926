
import { defineComponent } from 'vue'
import Button from '@/components/General/Button.vue'

export default defineComponent({
	name: 'Error404',
	components: {
		Button,
	},
})
