import { useUserDataStore } from '@/stores/data/UserDataStore'
import { useAppDataStore } from '@/stores/data/AppDataStore'
import { useToast } from 'vue-toastification'
import { useContentDisplayDataStore } from '@/stores/data/ContentDisplayDataStore'

export default async (to: any, from: any) => {
	const userData = useUserDataStore()
	const toast = useToast()

	// Save query params to cookies
	// Marketing data are saved to cookies to be used in the future
	if(to.query.promo) {
		document.cookie = `promo=${to.query.promo};`
	}
	if(to.query.utm_content) {
		document.cookie = `utmContent=${to.query.utm_content};`
	}
	if(to.query.utm_source) {
		document.cookie = `utmSource=${to.query.utm_source};`
	}
	if(to.query.utm_campaign) {
		document.cookie = `utmCampaign=${to.query.utm_campaign};`
	}
	if(to.query.utm_medium) {
		document.cookie = `utmMedium=${to.query.utm_medium};`
	}

	const token = to.query.token

	if(token) {
		localStorage.setItem('jwt', token)
	}

	const lang = to.query.lang
	if(lang && useAppDataStore().i18n().availableLocales.includes(lang)){
		useAppDataStore().i18n().locale = lang
	}

	await userData.setUser(to)
		.catch(() => {
			const error = token ? 'errors.invalidToken': 'errors.tokenExpired'
			toast.error(useAppDataStore().i18n()
				.t(error))
		})
		
	if(!userData.isLoggedIn){
		if(!useContentDisplayDataStore().contentDisplays){
			useContentDisplayDataStore().fetchContentDisplays(useAppDataStore().i18n().locale)
		}
	}

	const toLogin = () => ({
		name: 'sign-in',
		props: from,
	})

	if(to.meta.protected && !userData.isLoggedIn) {
		return toLogin()
	}
}