import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/icons/burger-menu.svg'


const _withScopeId = n => (_pushScopeId("data-v-687ea2ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "max-h-screen overflow-auto w-full flex flex-col md:pb-0" }
const _hoisted_2 = { class: "max-w-screen-2xl w-full place-self-center flex flex-col md:flex-row flex-grow relative pb-7" }
const _hoisted_3 = { class: "flex flex-col md:hidden p-4 gap-2" }
const _hoisted_4 = { class: "flex flex-grow max-w-full content px-2 md:px-0 md:pr-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopBar = _resolveComponent("TopBar")!
  const _component_SideBar = _resolveComponent("SideBar")!
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TopBar, { class: "hidden md:block" }),
    _createElementVNode("main", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "Menu",
          class: "h-6 w-6",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.appStore.isMenuOpened = true))
        }),
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t(`menu.${_ctx.$route.name}`)), 1)
      ]),
      _createVNode(_component_SideBar, { class: "side" }),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            _createVNode(_component_RouterView)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}