import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-screen h-screen flex justify-center items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingAppAnimation = _resolveComponent("LoadingAppAnimation")!
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex flex-row h-full text-text", [_ctx.showAppBackground ? 'bg-background-other' : 'bg-background-plain']])
  }, [
    _createVNode(_component_RouterView, null, {
      default: _withCtx(({ Component }) => [
        _createVNode(_Transition, {
          name: "fade",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (_ctx.isAppLoading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_LoadingAppAnimation)
                ]))
              : (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { key: 1 }))
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    })
  ], 2))
}