export enum WalletOpSide {
	deposit = 'DEPOSIT',
	withdraw = 'WITHDRAWAL',
	interest = 'INTEREST',
  }
export enum WalletOpStatus {
	// Scala statuses
	pending = 'PENDING',
	canceled = 'CANCELED',
	finished = 'FINISHED',
	completed = 'COMPLETED',
	waitingForApproval = 'WAITING_FOR_APPROVAL',
	approved = 'APPROVED',
	aborted = 'ABORTED',
	expired = 'EXPIRED',
	// Fiat statuses
	created = 'CREATED',
	paymentReceived = 'PAYMENT_RECEIVED',
	paymentToReturn = 'PAYMENT_TO_RETURN',
	paymentApproved = 'PAYMENT_APPROVED',
	underVerification = 'UNDER_VERIFICATION',
	exchangeProcessed = 'EXCHANGE_PROCESSED',
	unsupportedState = 'UNSUPPORTED_STATE',
	hedged = 'HEDGED',
	credited = 'CREDITED',
}
export enum WalletOpType {
	lightningWithdraw = 'LIGHTNING_WITHDRAW',
	lightningDeposit = 'LIGHTNING_DEPOSIT',
	onchainWithdraw = 'ONCHAIN_WITHDRAW',
	onchainDeposit = 'ONCHAIN_DEPOSIT',
	fiatWithdraw = 'FIAT_WITHDRAW',
	fiatDeposit = 'FIAT_DEPOSIT',
	banxaDeposit = 'BANXA_DEPOSIT',
}
export enum PaymentFiatProvider {
	converterBtcexcz = 'CONVERTER_BTCEXCZ',
	bitcoinOnchain = 'BITCOIN_ONCHAIN',
	card = 'CREDIT_CARD',
	banxa = 'BANXA',
}

export enum PaymentMethodType {
	withdraw = 'WITHDRAWAL',
	deposit = 'DEPOSIT',
}

export enum CreditCardPaymentMethods {
	applePay = 'APPLE_PAY',
	visaMastercard = 'VISA_MASTERCARD',
	googlePay = 'GOOGLE_PAY',
}