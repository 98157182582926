import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "font-bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingCircle = _resolveComponent("LoadingCircle")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    is: _ctx.to === '#' ? 'button' : 'a',
    href: _ctx.computedHref,
    to: _ctx.computedTo,
    class: _normalizeClass(["transition-all inline-block text-center", [
			!_ctx.disabled ? ' hover:bg-opacity-80 cursor-pointer' : '',
			_ctx.colorClasses,
			_ctx.sizeClasses,
			_ctx.buttonClasses,
		]]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (!_ctx.disabled && !_ctx.loading && _ctx.onClick()))
  }, {
    default: _withCtx(() => [
      (_ctx.icon.length && !_ctx.loading)
        ? (_openBlock(), _createElementBlock("i", {
            key: 0,
            class: _normalizeClass('icon-' + _ctx.icon)
          }, null, 2))
        : _createCommentVNode("", true),
      (_ctx.variant === 'outline')
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["h-full w-full rounded-full font-bold", `${_ctx.outlineSizeClasses} ${_ctx.background}`])
          }, [
            (!_ctx.loading && _ctx.variant === 'outline')
              ? _renderSlot(_ctx.$slots, "default", { key: 0 })
              : _createCommentVNode("", true)
          ], 2))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (!_ctx.loading)
              ? _renderSlot(_ctx.$slots, "default", { key: 0 })
              : _createCommentVNode("", true)
          ])),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_LoadingCircle, {
            key: 3,
            class: "w-8 h-8 -mt-1 -mb-1 block"
          }))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["is", "href", "to", "class"]))
}