import { defineStore } from 'pinia'
import axios, { AxiosResponse, AxiosError } from 'axios'
import { IMarketDataStore } from '@/interfaces'
import { useMetaDataStore } from '@/stores/data/MetaDataStore'
import {
	Instrument,
	InstrumentDto,
	PricePoint,
	Option,
	FilterItem,
	InstrumentFilter,
} from '@/types'


export const useMarketDataStore = defineStore({
	id: 'marketData',
	state: (): IMarketDataStore => ({
		instruments: new Map(),
		totalCount: 0,
	}),
	getters: {
		sectors: (): Array<string> => {
			return useMetaDataStore().sectorsList
		},
		countries: (): Array<Option> => {
			return useMetaDataStore().countries
		},
		currencies: (): Array<string> => {
			return useMetaDataStore().currenciesList
		},
		categories: (): Array<FilterItem> => {
			return useMetaDataStore().instrumentTypesList.map((e)=> ({
				id: e,
				name: `instrumentTypes.${e.toLocaleLowerCase()}`,
			}))
		},
	},
	actions: {
		async fetchInstruments(payload: InstrumentFilter) {
			await axios
				.get('instrument', { params: { ...payload } })
				.then((response: AxiosResponse) => {
					this.totalCount = response.data.totalCount
					this.instruments.clear()
					// Instruments
					response.data.instruments.map((instrument: InstrumentDto) => {
						instrument.pricePoint.bid = Number.parseFloat(
							instrument.pricePoint.bid.toFixed(instrument.quotePrecision),
						)
						instrument.pricePoint.ask = Number.parseFloat(
							instrument.pricePoint.ask.toFixed(instrument.quotePrecision),
						)
						instrument.pricePoint.lastPrice = Number.parseFloat(
							instrument.pricePoint.lastPrice.toFixed(instrument.quotePrecision),
						)
						const change = {
							price:
								instrument.pricePoint.lastPrice - instrument.lastClosePrice,
							percent: instrument.priceChange,
						}

						this.instruments.set(instrument.id, { ...instrument, change })
					})
				})
				.catch((error: AxiosError) => {
					console.error(error)
				})
		},
		updateInstrumentPricePoint(id: number, pricePoint: PricePoint) {
			const instrument = this.instruments.get(id)
			if (instrument) {
				instrument.pricePoint = {
					bid: Number.parseFloat(pricePoint.bid.toFixed(instrument.quotePrecision)),
					ask: Number.parseFloat(pricePoint.ask.toFixed(instrument.quotePrecision)),
					instrumentId: pricePoint.instrumentId,
					lastPrice: Number.parseFloat(pricePoint.lastPrice.toFixed(instrument.quotePrecision)),
					symbol: pricePoint.symbol,
					timestamp: pricePoint.timestamp,
				}
				this.instruments.set(id, instrument)
			}
		},
	},
})
