import { AxiosError, AxiosResponse } from 'axios'
import { defineStore } from 'pinia'
import { useOrderDataStore } from '@/stores/data/OrderDataStore'
import { useInstrumentDataStore } from '@/stores/data/InstrumentDataStore'
import { ITradeViewStore } from '@/interfaces/modules/ITradeViewStore'
import { EnhancedOrder, Instrument, Order } from '@/types'
import { OrderFormType, OrderSide, GtmCategory, GtmEvents, TradeViews, SubOverview } from '@/enums'
import { gtmPush } from '@/helpers/gtm'
import {handleAxios} from '@/helpers/errors'


export const useTradeViewStore = defineStore('tradeView', {  
	state: (): ITradeViewStore => {
		return {
			search: '',
			fullscreen: false,
			
			// Instruments filters
			selectedInstrumentId: null,
			selectedInstrumentRating: [],
			// Orders filters
			orderFilterActive: 'OPEN',
			selectedOrderId: '',

			// Side bar
			orderFormOpened: false,
			orderFormType: OrderFormType.buy,
			selectedOverviewType: SubOverview.CHART,

			selectedType: TradeViews.OVERVIEW,
		}
	},
	getters: {
		instruments(): Array<Instrument> {
			return [...useInstrumentDataStore().instruments.values()]
		},
		popularInstruments(): Array<Instrument> {
			return [...useInstrumentDataStore().popularInstruments.values()]
		},
		mappedOrders(): Array<EnhancedOrder> {
			return this.orders
				.filter((order) =>  order.instrumentId === this.selectedInstrumentId)
				.map((order) => {
					const instrument = this.instruments.find((instrument) => instrument.id === order.instrumentId) as Instrument
					
					return ({ 
						...order,
						instrumentName: instrument.name,
						quoteAsset: instrument.quoteAsset,
					})
				})
		},
		orders(): Array<Order> {
			return useOrderDataStore().orders
		},
		filteredInstruments(): Array<Instrument> {
			return this.instruments.filter((instrument) => {
				const containsName = instrument.name
					.toLowerCase()
					.includes(this.search.toLowerCase())

				const containsSymbol = instrument.symbol
					.toLowerCase()
					.includes(this.search.toLowerCase())

				return containsName || containsSymbol
			})
		},
		selectedInstrument(): Instrument | null {
			let returnInstrument = null as Instrument | null
			useInstrumentDataStore().instruments.forEach((instrument) => {
				if (instrument.id === this.selectedInstrumentId) {
					returnInstrument = instrument
				}
			})
			return returnInstrument
		},
	},
	actions: {
		searchInstrument(value: string) {
			gtmPush(GtmCategory.TRADE, GtmEvents.INSTRUMENT_SEARCHED, {inputFieldID: 'search', userInput: value})
			this.search = value
		},
		async initialize() {
			this.selectedInstrumentId = this.instruments[0].id
			useInstrumentDataStore().fetchTrends(this.selectedInstrumentId)
				.then(() => {
					this.selectedInstrumentRating = useInstrumentDataStore().ratings
				})
		},  
		setOrderFilterActive(filter: 'OPEN' | 'PENDING') {
			this.orderFilterActive = filter
		},
		selectInstrument(instrument: Instrument) {
			gtmPush(GtmCategory.TRADE, GtmEvents.INSTRUMENT_DETAIL, {selectedInstrument: instrument.name})
			this.selectedInstrumentId = instrument.id
			this.selectedType = TradeViews.OVERVIEW
			this.selectedOverviewType = SubOverview.CHART
			useInstrumentDataStore().fetchTrends(instrument.id)
				.then(() => {
					this.selectedInstrumentRating = useInstrumentDataStore().ratings
				})
		},
		setOrderId(id: string) {
			this.selectedOrderId = id
		},
		openCreateOrder(side: OrderFormType) {
			this.selectedType = TradeViews.ORDER
			if (side === OrderFormType.buy) {
				gtmPush(GtmCategory.TRADE, GtmEvents.PROCEEDED_TO_BUY, {selectedInstrument: this.selectInstrument.name})
			} else { 
				gtmPush(GtmCategory.TRADE, GtmEvents.PROCEEDED_TO_SELL, {selectedInstrument: this.selectInstrument.name})
			}

			if (!this.orderFormOpened) {
				this.orderFormOpened = true
			}

			this.orderFormType = side
		},
		closeSideBar() {
			this.selectedType = TradeViews.OVERVIEW
			this.orderFormOpened = false
		},
		async closeOrder(orderId: string): Promise<boolean> {
			const orderDataStore = useOrderDataStore()

			try {
				return await orderDataStore
					.closeOrder(orderId)
					.then((res: AxiosResponse) => {
						if(res.status !== 200) {
							throw new Error(res.data)
						}
						this.closeSideBar()
						return true
					})
			} catch (error) {
				handleAxios(error as AxiosError)
				return false
			}
		},
		async createOrder({ quantity, leverage, price, stopLoss, takeProfit }: { quantity: number, leverage: number, price?: number, stopLoss?: number, takeProfit?: number }):Promise<boolean> {
			const orderDataStore = useOrderDataStore()

			try {
				if (this.selectedInstrument === null) return false

				const orderSide = this.orderFormType === OrderFormType.buy 
					? OrderSide.buy 
					: OrderSide.sell

				const createOrderDto = {
					instrumentId: this.selectedInstrumentId ?? 0,
					quantity,
					leverage,
					side: orderSide,
					...price && { price },
					...stopLoss && { stopLoss },
					...takeProfit && { takeProfit },
				}

				return await orderDataStore
					.createOrder(createOrderDto)
					.then((res: AxiosResponse) => {
						if(res.status !== 200) {
							throw new Error(res.data)
						}
						this.closeSideBar()
						return true
					})
			} catch (error) {
				handleAxios(error as AxiosError)
				return false
			}
		},
		async updateOrder(orderId: string, stopLoss: number, takeProfit: number, newMargin: number):Promise<boolean> {
			const orderDataStore = useOrderDataStore()

			try {
				const updateOrderDto = {
					orderId: orderId,
					...stopLoss && { stopLoss },
					...takeProfit && { takeProfit },
					...newMargin && { newMargin },
				}

				return await orderDataStore
					.updateOrder(updateOrderDto)
					.then((res: AxiosResponse) => {
						if (res.status === 200) {
							useOrderDataStore().updateLocalOrder(res.data as Order)
							this.closeSideBar()
							return true
						}
						throw new Error(res.data)
					})
			} catch (error) {
				handleAxios(error as AxiosError)
				return false
			}
		},
	},
})

