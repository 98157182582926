import { defineStore } from 'pinia'
import axios, { AxiosResponse, AxiosError } from 'axios'
import { IWalletDataStore } from '@/interfaces'
import { BanxaOrder, BanxaPaymentMethod, FiatPaymentMethod, FiatWithdrawalResponse } from '@/types'
import { Currency, OrderSide, WalletOpType } from '@/enums'
import {
	CreateDeposit,
	FiatOrderDetail,
	SwapRate,
	WalletAmount,
	WithdrawalRequest,
} from '@/types/modules/Wallet'

export const useWalletDataStore = defineStore({
	id: 'walletData',
	state: (): IWalletDataStore => ({
		transactions: [] as Array<FiatOrderDetail>,
		paymentMethods: [] as Array<FiatPaymentMethod>,
		swapsRate: null,
		bitcoinAddress: '',
		bitcoinConfirmations: '',
		banxaPaymentMethods: {} as Record<string, BanxaPaymentMethod>,
		banxaCurrenciesMethods: {} as Record<Currency, string[]>,
	}),

	actions: {
		async getOrderDetails(orderId: string): Promise<BanxaOrder | null> {
			return await axios
				.get('deposit/order-created/' + orderId)
				.then((response: AxiosResponse) => {
					return response.data as BanxaOrder
				})
				.catch((error: AxiosError) => {
					console.error(error)
					return null
				})
		},
		async postCreateBanxaOrder(amount: number, currency: Currency, paymentMethodId: number): Promise<string | null> {
			const result = await axios
				.post('deposit/create-banxa', {
					amount,
					currency,
					paymentTypeId: String(paymentMethodId),
				}).catch((error: AxiosError) => {
					console.error(error)
					return null
				})
			return result?.data.checkoutUrl ?? null
		},
		async fetchBanxaPaymentMethods(orderSide = OrderSide.buy) {
			await axios
				.get(`deposit/payments-methods/${orderSide == OrderSide.buy ? 'Buy' : 'Sell'}`)
				.then((response: AxiosResponse) => {
					if (response.data.isError || !response.data.response) {
						return
					}
					this.banxaPaymentMethods = response.data.response.paymentMethods
					this.banxaCurrenciesMethods = response.data.response.currenciesMethods
				})
				.catch((error: AxiosError) => {
					console.error(error)
				})
		},
		async fetchSwapRate(paymentMethodId: number) {
			this.swapsRate = null
			await axios
				.get('swap-rate?paymentMethodId=' + paymentMethodId)
				.then((response: AxiosResponse) => {
					this.swapsRate = response.data as SwapRate
				})
				.catch((error: AxiosError) => {
					console.error(error)
				})
		},
		async fetchBitcoinAddress () {
			await axios
				.get('deposit/bitcoin-address')
				.then((response: AxiosResponse) => {
					this.bitcoinAddress = response.data.address
					this.bitcoinConfirmations = response.data.confirmations
				})
				.catch((error: AxiosError) => {
					console.error(error)
				})
		},
		async fetchPaymentMethods() {
			await axios
				.get('payment-method')
				.then((response: AxiosResponse) => {
					this.paymentMethods = response.data as Array<FiatPaymentMethod>
					this.paymentMethods.forEach((method) => {
						if (method.minimum)
							method.minimum.value = Number(method?.minimum?.value).toFixed(
								method?.minimum?.precision,
							)
					})
				})
				.catch((error: AxiosError) => {
					console.error(error)
				})
		},
		async fetchTransactions() {
			await axios
				.get('walletOrders')
				.then((response: AxiosResponse) => {
					this.transactions = response.data as Array<FiatOrderDetail>
					this.transactions.forEach((transaction) => {
						transaction.amount.value = Number(transaction.amount.value).toFixed(
							transaction.amount.precision,
						)
					})
				})
				.catch((error: AxiosError) => {
					console.error(error)
				})
		},
		getDepositTransaction(id: string) {
			return (
				this.transactions.find((transaction) => transaction.id === id) ?? null
			)
		},
		getWithdrawTransaction(id: string) {
			return (
				this.transactions.find((transaction) => transaction.id === id) ?? null
			)
		},
		async postDeposit(opType: WalletOpType, amount: WalletAmount) {
			await axios
				.post(
					'deposit',
					{
						opType,
						amount,
					},
					{
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					},
				)
				.then((response: AxiosResponse) => {
					console.log(response)
				})
				.catch((error: AxiosError) => {
					console.error(error)
				})
		},
		async postFiatWithdraw(walletRequest: WithdrawalRequest): Promise<FiatWithdrawalResponse | boolean> {
			return await axios
				.post('withdrawal', walletRequest)
				.then((response: AxiosResponse) => {
					return response.data as FiatWithdrawalResponse
				})
				.catch((error: AxiosError) => {
					return false
				})
		},
		async postFiatDeposit(
			createDeposit: CreateDeposit,
		): Promise<FiatOrderDetail | boolean> {
			return await axios
				.post('deposit', createDeposit)
				.then((response: AxiosResponse) => {
					return response.data as FiatOrderDetail
				})
				.catch((error: AxiosError) => {
					return false
				})
		},
	},
})
