import App from './App.vue'
import axios from 'axios'
import {createApp} from 'vue'
import {createPinia} from 'pinia'
import router from './router'
import {useSocketUtilityStoreWithOut} from '@/stores/utility/SocketUtilityStore'
import VueAxios from 'vue-axios'
import { useUserDataStore } from '@/stores/data/UserDataStore'
//@ts-ignore
import VueNativeSock from 'vue-native-websocket-vue3'
import Vue3Lottie from 'vue3-lottie'
import { vMaska } from 'maska'
import Toast, { POSITION } from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import './assets/tailwind.css'
import './assets/css/font-derivato.css'
import './assets/css/font-inter.css'
import { i18n } from './i18n'
import filters from './helpers/filters'
import masks from './helpers/masks'
import { RouteNames } from './enums'
import { useI18n } from 'vue-i18n'

const store = createPinia()
const piniaSocketStore = useSocketUtilityStoreWithOut(store)

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $filters: typeof filters
    $masks: typeof masks
  }
}

const app = createApp(App)
	.use(i18n)
	
app.mixin({
	setup() {
		return {
			// Proxy $t function for inject global variables
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			$t(key: string, params?: Record<string, any>) {
				return useI18n().t(key, { 
					// Global variables that can be used in translations
					brand: String(window.__config__.brand.toLowerCase()).charAt(0)
						.toUpperCase() + String(window.__config__.brand.toLowerCase()).slice(1), 
					...params,
				})
			},
		}
	},
})

const prepareApp = async () => {
	const config = window.__config__

	axios.defaults.baseURL = config.apiUrl
	axios.defaults.withCredentials = true

	axios.defaults.validateStatus = (status) => {
		return (status >= 200 && status < 300) || status == 403 || status == 401
	}

	axios.interceptors.response.use(function (response) {

		if (response.status == 401 || response.status == 403) {
			useUserDataStore().clearUserData()
			router.push({ name: RouteNames.SignIn})
		}

		return response
	}, function (error) {
		// Do something with response error
		return Promise.reject(error)
	})
	
	const socketURL = config.wsUrl

	app.provide('brand', config.brand.toLowerCase())
	app.provide('publicUrl', config.publicUrl)

	app.config.globalProperties.$filters = filters
	app.config.globalProperties.$masks = masks

	app
		.use(VueAxios, axios)
		.use(router)
		.use(store)
		.use(
			VueNativeSock,
			socketURL,
			{
				store: piniaSocketStore,
				connectManually: true,
				reconnection: true,
				reconnectionAttempts: Infinity,
				reconnectionDelay: 20000,
			},
		)
		.use(Vue3Lottie)
		.use(Toast, {
			position: POSITION.BOTTOM_RIGHT,
		})
		.directive('maska', vMaska)
		.mount('#app')

	return app
}

prepareApp()

export default app