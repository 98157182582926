import { defineStore } from 'pinia'

import { useSocketUtilityStore } from '@/stores/utility/SocketUtilityStore'
import { useInstrumentDataStore } from '@/stores/data/InstrumentDataStore'
import { useOrderDataStore } from '@/stores/data/OrderDataStore'
import { useMetaDataStore } from '@/stores/data/MetaDataStore'
import { useTradeViewStore } from '@/stores/views/TradeViewStore'
import { useUserDataStore } from './UserDataStore'
import { useContentDisplayDataStore } from '@/stores/data/ContentDisplayDataStore'
import { useWalletDataStore } from './WalletDataStore'
import { useI18n } from 'vue-i18n'

export const useAppDataStore = defineStore({
	id: 'appData',
	state: () => ({
		instrumentStore: useInstrumentDataStore(),
		orderStore: useOrderDataStore(),
		socketStore: useSocketUtilityStore(),
		metaDataStore: useMetaDataStore(),
		tradeViewStore: useTradeViewStore(),
		userDataStore: useUserDataStore(),
		contentDisplayStore: useContentDisplayDataStore(),
		walletDataStore: useWalletDataStore(),
		useI18n: useI18n(),
		isAppLoading: false,
		isMenuOpened: false,
		instrumentsInterval: undefined as undefined | ReturnType<typeof setInterval>,
	}),
	actions: {
		async authorization(jwt: string) {
			this.socketStore.connect(jwt)
			await useInstrumentDataStore().fetchInstruments()
			this.instrumentsInterval = setInterval(useInstrumentDataStore().fetchInstruments, 60 * 1000)
			await this.orderStore.fetchPortfolio()
			await this.metaDataStore.fetchMetaData()
			this.contentDisplayStore.fetchContentDisplays(this.useI18n.locale)
			await this.walletDataStore.fetchTransactions()
		},
		stopInterval() {
			clearInterval(this.instrumentsInterval)
		},
		i18n() {
			return this.useI18n
		},
	},
})
