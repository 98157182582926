export enum TradeViews {
	OVERVIEW = 'overview',
	ORDER = 'order',
	INSTRUMENTS = 'instruments',
}

export enum SubOverview {
	CHART = 'chart',
	ANALYSES = 'analyses',
	GENERAL = 'general',
}