import { createI18n, LocaleMessages, VueMessageType } from 'vue-i18n'
import { I18n } from 'vue-i18n'
import commonTranslations from './locales/common.json'

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages(): LocaleMessages<any> {
	const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)

	const messages: LocaleMessages<VueMessageType> = {}
	locales.keys()
		.filter((key) => key !== './common.json')
		.forEach((key) => {
			const matched = key.match(/([A-Za-z0-9-_]+)\./i)
			if (matched && matched.length > 1) {
				const locale = matched[1]
				messages[locale] = locales(key).default
			}
		})

	Object.keys(messages).forEach((lang:string) => {
		Object.keys(commonTranslations).forEach((key:string) => {
			const langMessages = messages[lang] as any
			if (langMessages && typeof langMessages[key] === 'undefined') {
				langMessages[key] = commonTranslations[key as keyof typeof commonTranslations]
			}
		})
	})

	return messages
}

export const i18n: I18n = createI18n({
	legacy: false,
	locale: window.__config__.defaultLocale || 'en',
	fallbackLocale: window.__config__.defaultLocale || 'en',
	messages: loadLocaleMessages(),
}) as I18n
