enum Trends {
  BUY = 'buy',
  OUTPERFORM = 'outperform',
  OVERWEIGHT = 'overweight',
  STRONG_BUY = 'strongbuy',
  HOLD = 'hold',
  NEUTRAL = 'neutral',
  EQUAL_WEIGHT = 'equal-weight',
  UNDER_PERFORM = 'underperform',
  UNDERWEIGHT = 'underweight',
  SELL = 'sell', 
}

export default Trends