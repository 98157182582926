import { defineStore } from 'pinia'
import { IAccountStore } from '@/interfaces'
import { useUserDataStore } from '../data/UserDataStore'
import { useMetaDataStore } from '../data/MetaDataStore'
import { Currency } from '@/enums'
import axios, { AxiosError, AxiosResponse } from 'axios'

export const useAccountViewStore = defineStore('accountView', {
	state: (): IAccountStore => {
		return {
			name: '',
			surname: '',
			password: '',
			notifications: false,
			newsletter: false,
			phone: '',
			address: '',
			city: '',
			zip: '',
			country: '',
			language: 'en',
			deleteAccountModal: false,
			supportName: '',
			supportMessage: '',
		}
	},
	getters: {
		userID(): string {
			return useUserDataStore().user?.id ?? ''
		},
		userEmail(): string {
			return useUserDataStore().user?.email ?? ''
		},
		userCurrency(): Currency {
			return useUserDataStore().userCurrency
		},
		userBalance(): number {
			return useUserDataStore().userBalance
		},
		userFullName(): string {
			return `${this.name} ${this.surname}`
		},
		userInicials(): string {
			return (this.name && this.surname) ? `${this.name[0]}${this.surname[0]}` : ''
		},
	},
	actions: {
		deleteAccount() {
			this.deleteAccountModal = true
		},
		deleteAccountConfirm() {
			console.log('delete account confirmed')
			//TODO: delete account endpoint
		},
		onDeleteAccountClose() {
			this.deleteAccountModal = false
		},
		setLanguage(lang: string) {
			this.language = lang
		},
		async sendSupportEmail() {
			return await axios
				.post('contact/support', {
					name: this.supportName,
					message: this.supportMessage,
				})
		},
	},
})

