
import { defineComponent } from 'vue'
import { RouterView } from 'vue-router'

import TopBar from '@/layouts/appLayout/TopBar.vue'
import SideBar from '@/layouts/appLayout/SideBar.vue'
import { useAppDataStore } from '@/stores/data/AppDataStore'

export default defineComponent({
	name: 'AppLayout',
	components: {
		TopBar,
		SideBar,
		RouterView,
	},
	data() {
		return {
			appStore: useAppDataStore(),
		}
	},
})
