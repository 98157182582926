import { GtmCategory, GtmEvents } from '@/enums'
import { useUserDataStore } from '@/stores/data/UserDataStore'

export function gtmPush(category: GtmCategory, event: GtmEvents, extra?: any): void {

	let payload = {
		event: 'trigger',
		event_name: event,
		category: category,
		language: useUserDataStore().user.lang,
		...extra,
	}

	if(useUserDataStore().isLoggedIn) {
		const fullName = useUserDataStore().user?.kyc?.name ?? '' +  useUserDataStore().user?.kyc?.surname
		payload = {...payload, userID: useUserDataStore().user.uuid, userInfo: {
			email: useUserDataStore().user?.kyc?.email ?? undefined,
			name: fullName,
		}}
	}

	window.dataLayer.push(payload)
}
