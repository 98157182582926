// @ts-check
import { defineStore } from 'pinia'
import { IUserDataStore } from '@/interfaces/modules/IUserDataStore'
import axios from 'axios'
import router from '@/router'
import { User, ChangePasswordType, ResetPasswordType, LocationData } from '@/types'
import { Currency, RouteNames, GtmCategory, GtmEvents} from '@/enums'
import { useMetaDataStore } from '@/stores/data/MetaDataStore'
import { useAppDataStore } from '@/stores/data/AppDataStore'
import { i18n } from '@/i18n'
import { gtmPush } from '@/helpers/gtm'
import { useSocketUtilityStore } from '@/stores/utility/SocketUtilityStore'
import { useContentDisplayDataStore } from '@/stores/data/ContentDisplayDataStore'

type signUpExtendedType =  {
	username: string
	password: string
	name: string
	phone: string
	country: string
	surname: string
	lang: string
	termsOfUse: boolean
	privacyPolicy: boolean
	promo?: string
	utmContent?: string
	utmSource?: string
	utmCampaign?: string
	utmMedium?: string
}
const defaultUser = () => {
	return {
		uuid: '',
		status: '',
		lang: 'en',
		id: '',
		satsBalance: 0,
		eurBalance: 0,
		balanceHolds: [],
		createdAt: 0,
		kyc: {
			updatedAt: '',
			userUuid: '',
			name: '',
			surname: '',
			email: '',
			verificationRequired: 0,
			verificationUrl: '',
		},
	}
}
export const useUserDataStore = defineStore({
	id: 'userDataStore',
	state: (): IUserDataStore => ({
		user: defaultUser(),
		activationFailedAttempts: 0,
	}),
	getters: {
		isLoggedIn(): boolean {
			return !!this.user.uuid
		},
		userCurrency(): Currency {
			if (useMetaDataStore().baseCurrency == Currency.BTC) {
				return Currency.SAT
			}
			return useMetaDataStore().baseCurrency ?? Currency.EUR
		},
		userBalance(): number {
			if (useMetaDataStore().baseCurrency === Currency.BTC) {
				return this.user?.satsBalance ?? 0
			}
			return this.user?.eurBalance ?? 0
		},
		userHolds(): number {
			if (!this.user?.uuid) return 0
			let holds = 0
			this.user.balanceHolds.forEach((hold) => {
				holds += hold.holdAmount
			})
			return holds
		},
		fullName(): string {
			return `${this.user.kyc?.name} ${this.user.kyc?.surname}`
		},
		userTotalBalance(): number {
			return this.userBalance
		},
	},
	actions: {
		async getGeoLocation(): Promise<LocationData | null>{
			const geoApiKey = window.__config__.geoApiKey
			try {
				const locationsData = await axios.get(`https://api.ipgeolocation.io/ipgeo?apiKey=${geoApiKey}`)
				if(!locationsData.data || !locationsData.data.country_code3){
					return null
				}
				return locationsData.data
			} catch (error) {
				console.error(error)
				return null
			}
		},
		async setUser(redirect: any) {
			if (this.isLoggedIn) return
			const jwt = localStorage.getItem('jwt')
			if (!jwt || jwt === undefined) {
				this.clearUserData()
				return
			}
			axios.defaults.headers.common['Authorization'] = `Bearer ${jwt}`
			useAppDataStore().isAppLoading = true
			await this.getUser()

			if(this.isLoggedIn) {
				await useAppDataStore().authorization(jwt)
				router.push(redirect)
			} else {
				useAppDataStore().isAppLoading = false
				throw Error()
			}
			useAppDataStore().isAppLoading = false
			return
		},
		async login(username: string, password: string, redirect: string) {
			await this.signIn(username, password).then(async (res) => {
				if (res.status === 200 && res.data.authorization !== undefined) {
					const jwt = res.data.authorization
					localStorage.setItem('jwt', jwt)
					axios.defaults.headers.common['Authorization'] = `Bearer ${jwt}`
					useAppDataStore().isAppLoading = true
					await this.getUser()
					if(this.isLoggedIn) {
						await useAppDataStore().authorization(jwt)
							.finally(() => 
								router.push(redirect === '' ? { name: RouteNames.Dashboard } : redirect),
							)
					}
					useAppDataStore().isAppLoading = false
				} else {
					return Promise.reject('error')
				}
			})
		},
		clearUserData() {
			useAppDataStore().stopInterval()
			useSocketUtilityStore().disconnect()
			localStorage.removeItem('jwt')
			document.cookie = 'accessToken='
			axios.defaults.headers.common['Authorization'] = ''
			this.user = defaultUser()
		},
		logout() {
			this.signOut().catch((error) => {
				console.error(error)
			})
			this.clearUserData()
			useContentDisplayDataStore().fetchContentDisplays(useAppDataStore().i18n().locale)
		},
		async signUp(username: string, password: string) {
			return await axios.post('user/sign-up', { username, password })
		},
		async signUpExtended({ username, password, name, phone, country, surname, lang, termsOfUse, privacyPolicy, promo, utmContent, utmCampaign, utmMedium, utmSource }: signUpExtendedType) {
			return await axios.post('user/sign-up-extended', { username, password, name, phone, state: country, surname, lang, termsOfUse, privacyPolicy, promo, utmContent, utmCampaign, utmMedium, utmSource })
		},
		async signOut() {
			return await axios.get('user/sign-out')
		},
		async signIn(username: string, password: string) {
			return await axios.post('user/sign-in', { username, password })
		},
		async lnCheckState(k1: string) {
			return await axios.get('lnurl/check-state', { params: { k1 } })
		},
		async lnCreateChallenge() {
			return await axios.get('lnurl/create-challenge')
		},
		async lnSubmitChallenge(k1: string, sign: string, key: string) {
			return await axios.get('lnurl/submit-challenge', { params: { k1, sign, key }})
		},
		async register(userName: string, password: string) {
			return await axios.post('user/signUp', { userName, password }, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
		},
		async sendVerificationCode(email: string) {
			gtmPush(GtmCategory.ACCOUNT_VERIFICATION, GtmEvents.RESEND_EMAIL)
			return await axios.post('get-verification-code', { email })
		},
		async activateAccount(code: string) {
			/*
				-	api request below will PROBABLY return status of verification represented by boolean value,
					that will increase activationFailedAttempted counter in case it's false  
				-	maybe store activationFailedAttempts in local storage, so some smarter users won't be able to
					fuck it up by refreshing the page
			*/
			return await axios.post('activate-account', { code })
				.then((response) => {
					if (!response.data) {
						this.activationFailedAttempts++
						return this.activationFailedAttempts
					}
					gtmPush(GtmCategory.ACCOUNT_VERIFICATION, GtmEvents.VERIFICATION_COMPLETED)
					return true
				})
				.catch((error) => {
					console.log(error)
					gtmPush(GtmCategory.ACCOUNT_VERIFICATION, GtmEvents.VERIFICATION_FAILED)
					this.activationFailedAttempts++
					return this.activationFailedAttempts
				})
		},
		async getUser() {
			const data = await this.getUserData()
			const info = await this.getUserInfo()
			if (!data || !info) {
				this.clearUserData()
				return
			}
			this.user = {...data, ...info}
			this.setLanguage(info.lang)
			gtmPush(GtmCategory.LOGIN, GtmEvents.LOGIN_SUCCEEDED)
		},
		async getUserData() {
			return await axios.get('user').then( response => {
				return response.data
			})
				.catch((error) => {
					console.error(error)
				})
		},
		async getUserInfo() {
			return await axios.get('user/info').then( response => {
				return response.data
			})
				.catch((error) => {
					console.error(error)
				})
		},
		async getWalletOps() {
			return await axios.get('user/walletOp').then((response) => {
				//this.walletOps = response.data
				console.log(response.data)
			})
		},
		async resetPassword(email: string) {
			return await axios.post('user/reset-password/request', { email })
		},
		async changePassword(payload: ResetPasswordType) {
			return await axios.post('user/reset-password/change', payload)
		},
		async setUserInfo() {
			const payload = {
				lang: this.user.lang,
			}
			return await axios.put('user/settings', payload)
		},
		async changeOldPassword(payload: ChangePasswordType) {
			return await axios.put('user/password/change', payload)
		},
		updateUser(user: User) {
			this.user = { ...this.user, ...user}
		},
		setLanguage(lang: string) {
			//@ts-ignore
			i18n.global.locale.value = lang
		},
	},
})