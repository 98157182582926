enum Sectors {
  COMMODITY = 'Commodity',
  COMMUNICATION_SERVICES = 'Communication Services',
  CONSUMER_CYCLICAL = 'Consumer Cyclical',
  CONSUMER_DEFENSIVE = 'Consumer Defensive',
  CRYPTO = 'Crypto',
  ETF = 'ETF',
  ENERGY = 'Energy',
  FINANCIAL_SERVICES = 'Financial Services',
  FOREX = 'Forex',
  HEALTHCARE = 'Healthcare',
  INDEX = 'Index',
  INDUSTRIALS = 'Industrials',
  TECHNOLOGY = 'Technology',
  UTILITIES = 'Utilities',
}
export default Sectors