<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		style="background:transparent;display:block;"
		width="200px"
		height="200px"
		viewBox="0 0 100 100"
		preserveAspectRatio="xMidYMid"
		class="max-w-[50px] max-h-[50px] mx-auto"
	>
		<circle
			cx="50"
			cy="50"
			fill="none"
			stroke="#ffffff"
			stroke-width="10"
			r="35"
			stroke-dasharray="164.93361431346415 56.97787143782138"
		>
			<animateTransform
				attributeName="transform"
				type="rotate"
				repeatCount="indefinite"
				dur="1s"
				values="0 50 50;360 50 50"
				keyTimes="0;1"
			/>
		</circle>
	</svg>
</template>