export enum GtmEvents {
	CONSENT_TO_PNP = 'Consent to Privacy Policy',
	CONSENT_TO_TNC = 'Consent to T&C',
	PAGE_OPEN = 'Page Opened',
	CLICKED_HAVE_ACCOUNT = 'Already have an Account',
	REGISTRATION_START = 'Proceeded to Signup',
	REGISTRATION_COMPLETED = 'Registration Completed',
	REGISTRATION_FAILED = 'Registration Failed',
	ACTIVATE_ACCOUNT = 'Activate Account',
	VERIFICATION_COMPLETED = 'Verification Completed',
	VERIFICATION_FAILED = 'Verification Failed',
	RETURN_TO_LOGIN = 'Return to Login',
	RESEND_EMAIL = 'Resend Email',
	PROCEEDED_TO_LOGIN = 'Proceeded to Login',
	LOGIN_SUCCEEDED = 'Login Succeeded',
	LOGIN_FAILED = 'Login Failed',
	DO_NOT_HAVE_AN_ACCOUNT = "Don't have an Account",
	FORGOT_PASSWORD = 'Forgot Password',
	PASSWORD_RESET_REQUIRED = 'Password Reset Requested',
	PASSWORD_CHANGE_CONFIRMED = 'Password Change Confirmed',
	RETURN_HOME = 'Return Home',
	LEFT_NAVIGATION_USED = 'Left Navigation Used',
	DEPOSIT = 'Deposit',
	ACCOUNT_SETTINGS = 'Account Settings',
	LOGOUT = 'Logout',
	INFORMATION_BANNER_OPENED = 'Information Banner Opened',
	VERIFICATION_BANNER_CLICKED = 'Verification Banner Clicked',
	DEPOSIT_BANNER_CLICKED = 'Deposit Banner Clicked',
	PAGE_SIZE_SET = 'Pagination: Page Size Set',
	PAGE_NAVIGATION = 'Pagination: Page Navigation',
	INSTRUMENT_SEARCHED = 'Instrument Searched',
	INSTRUMENT_FILTERED_TYPE = 'Instruments Filtered (by type)',
	INSTRUMENT_FILTERED_SECTOR = 'Instruments Filtered (by sector)',
	INSTRUMENT_FILTERED_CURRENCY = 'Instruments Filtered (by currency)',
	INSTRUMENT_SORTED = 'Instruments Sorted',
	INSTRUMENT_DETAIL = 'Instrument Detail',
	WITHDRAW = 'Withdraw',
	PAGE_SIZE = 'Page Size',
	CURRENCY_CHOSEN = 'Currency Chosen',
	PROCEEDED_TO_EXCHANGE = 'Proceeded to Exchange',
	PREDEFINED_AMOUNT_SELECTION = 'Predefined Amount Selection',
	GENERATE_PAYMENT_INFO = 'Generate Payment Info',
	COPY_PAYMENT_INFO = 'Copy Payment Info',
	CLOSE = 'Close',
	PROCEEDED_TO_WITHDRAWAL = 'Proceeded to Withdrawal',
	INSTRUMENT_TYPE_FILTER = 'Instrument Type Filter',
	ORDER_TYPE_FILTER = 'Order Type Filter',
	ORDER_DETAIL = 'Order Detail',
	SEE_MORE = 'See More',
	ASSET_ALLOCATION_FILTER = 'Asset Allocation Filter',
	CLOSE_ORDER_DETAIL = 'Close Order Detail',
	SL_OR_TP_CLICKED = 'SL or TP CheckBox Clicked',
	SL_OR_TP_CHANGED = 'SL or TP Value Changed',
	ORDER_CHANGES_CONFIRMED = 'Order Changes Confirmed',
	ORDER_CLOSED = 'Order Closed',
	PROCEEDED_TO_BUY = 'Proceeded to Buy',
	PROCEEDED_TO_SELL = 'Proceeded to Sell',
	LEVERAGE_VALUE_CHANGED = 'Leverage Value Changed',
	ORDER_CONFIRMED = 'Order Confirmed',
}

export enum GtmCategory {
	REGISTRATION = 'Registration',
	ACCOUNT_VERIFICATION = 'Account Verification',
	LOGIN = 'Login',
	PASSWORD_RESET = 'Password Reset',
	PASSWORD_CHANGE = 'Password Change',
	GENERAL = 'General',
	MARKETS = 'Markets',
	WALLET = 'Wallet',
	DEPOSIT = 'Deposit',
	WITHDRAWAL = 'Withdrawal',
	DASHBOARD = 'Dashboard',
	PORTFOLIO = 'Portfolio',
	TRADE = 'Trade',
}
