
import { defineComponent, PropType } from 'vue'
import LoadingCircle from '@/components/General/LoadingCircle.vue'
import { RouteLocationRaw } from 'vue-router'

export default defineComponent({
	name: 'Button',
	components: {
		LoadingCircle,
	},
	emits: ['click'],
	props: {
		to: {
			type: [String, Object] as PropType<string | RouteLocationRaw>,
			required: false,
			default: '#',
		},
		size: {
			type: String,
			required: false,
			default: 'md', // sm, md, lg
		},
		color: {
			type: String,
			required: false,
			default: 'default', // default, primary, secondary, error, success, transparent
		},
		icon: {
			type: String,
			required: false,
			default: '',
		},
		variant: {
			type: String,
			required: false,
			default: 'solid', // outline, ghost, compact
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
		linear: {
			type: String,
			required: false,
			default: '',
		},
		loading: {
			type: Boolean,
			required: false,
			default: false,
		},
		background: {
			type: String,
			required: false,
			default: 'bg-background-drop',
		},
	},
	computed: {
		computedTo(){
			// Handling disabled state
			if(this.disabled){
				return '#'
			}

			// Handling external links
			if(typeof this.to === 'string' && this.to.startsWith('http')){
				return '#'
			}

			return this.to
		},
		computedHref(): string | undefined{
			if(typeof this.to === 'string' && this.to.startsWith('http')){
				return this.to
			}
			return undefined
		},
		colorClasses(): string {	
			// Handling disabled state
			if (this.disabled) {
				switch (this.variant) {
				case 'solid':
					return 'bg-text-tertiary text-background border border-text-tertiary cursor-not-allowed'
				case 'outline':
					return 'bg-transparent text-text-tertiary border border-text-tertiary cursor-not-allowed'
				case 'ghost':
					return 'bg-transparent text-text-tertiary border border-transparent cursor-not-allowed'
				}
			}

			// Handling default color since it's the only one that doesn't match the pattern
			if (this.color === 'default') {
				switch (this.variant) {
				case 'solid':
					return 'bg-background-paper text-text-secondary border border-background-secondary hover:bg-background-secondary'
				case 'outline':
					return ' text-text-secondary hover:text-secondary-hover hover:bg-background-secondary'
				case 'ghost':
					return 'bg-transparent text-text-secondary border border-transparent hover:bg-background-secondary'
				}
			}

			// Handling all other colors
			switch (this.variant) {
			case 'solid':
				return `bg-gradient-${this.color} text-button-${this.color} border-transparent hover:bg-gradient-${this.color}-hover hover:text-button-hover-${this.color}`
			case 'outline':
				return `bg-gradient-${this.color} text-button-${this.color} border-transparent hover:bg-gradient-${this.color}-hover hover:text-button-hover-${this.color}`
			case 'ghost':
				return `bg-transparent text-${this.color} border border-transparent`
			case 'compact':
				return `bg-${this.color} text-white border border-transparent`
			default:
				return ''
			}
		},
		sizeClasses(): string {
			let classes = ''
			switch (this.size) {
			case 'sm': {
				classes = classes + 'text-sm '
				if (this.variant === 'outline') {
					classes = classes + 'px-0.5 py-0.5'
				} else if(this.icon.length || this.variant == 'compact') {
					classes = classes + 'px-1 py-1'
				} else {
					classes = classes + 'px-6 py-1'
				}
			}
				return classes
			case 'md': {
				classes = classes + 'text-base '
				if (this.variant === 'outline') {
					classes = classes + 'px-0.5 py-0.5'
				} else if(this.icon.length || this.variant == 'compact') {
					classes = classes + 'px-2 py-2 '
				} else {
					classes = classes + 'px-6 py-2 '
				}
				return classes
			}
			case 'lg': {
				classes = classes + 'text-xl '
				if (this.variant === 'outline') {
					classes = classes + 'px-0.5 py-0.5'
				} else if(this.icon.length || this.variant == 'compact') {
					classes = classes + 'px-4 py-4 '
				} else {
					classes = classes + 'px-8 py-4 min-w-[180px] '
				}
				return classes
			}
			default: {
				classes = classes + 'text-base '
				if (this.variant === 'outline') {
					classes = classes + 'px-0.5 py-0.5'
				} else if(this.icon.length || this.variant == 'compact') {
					classes = classes + 'px-3 py-1 '
				} else {
					classes = classes + 'px-6 py-1 '
				}
				return classes
			}
			}
		},
		outlineSizeClasses(): string {
			switch (this.size) {
			case 'sm':
				return 'py-1 px-5 '
			case 'md':
				return 'py-1.5 px-12 '
			case 'lg':
				return 'py-3 px-14 '
			default:
				return 'py-1 px-5 '
			}
		},
		buttonClasses(): string {
			switch (this.variant) {
			case 'outline':
				return 'rounded-full'
			case 'solid':
				return 'rounded-full'
			case 'compact':
				return 'rounded'
			default:
				return 'rounded-full'
			}
		},
	},
	methods: {
		onClick() {
			this.$emit('click')
			if(typeof this.to === 'string' && this.to.startsWith('http')) {
				window.location.href = this.to
			}
		},
	},
})
