export default {
  "languages": {
    "cs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čeština"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
    "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español"])},
    "es-co": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español (Colombia)"])},
    "hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hrvatski"])},
    "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polski"])},
    "sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenčina"])},
    "sl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenščina"])}
  }
}