import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col md:ml-10 gap-3 md:w-fit" }
const _hoisted_2 = { class: "hidden md:flex flex-col text-center gap-1" }
const _hoisted_3 = { class: "text-[12px] text-text-secondary" }
const _hoisted_4 = { class: "font-bold text-base" }
const _hoisted_5 = { class: "flex flex-col text-center gap-0 md:gap-1 bg-gradient-summary mt-1 md:mt-0 py-4 px-2 mx-12 md:mx-0 rounded text-white" }
const _hoisted_6 = { class: "text-[12px]" }
const _hoisted_7 = { class: "text-xl font-bold" }
const _hoisted_8 = { class: "hidden md:flex flex-col text-center gap-1" }
const _hoisted_9 = { class: "text-[12px] text-text-secondary" }
const _hoisted_10 = { class: "text-sm font-bold" }
const _hoisted_11 = { class: "hidden md:flex flex-col text-center gap-1" }
const _hoisted_12 = { class: "text-[12px] text-text-secondary" }
const _hoisted_13 = { class: "md:hidden flex flex-row justify-around py-6" }
const _hoisted_14 = { class: "flex flex-col text-center gap-1" }
const _hoisted_15 = { class: "text-[12px] text-text-secondary" }
const _hoisted_16 = { class: "flex-col text-center gap-1 flex" }
const _hoisted_17 = { class: "text-[12px] text-text-secondary" }
const _hoisted_18 = { class: "font-bold text-base text-text" }
const _hoisted_19 = { class: "flex flex-col text-center gap-1" }
const _hoisted_20 = { class: "text-[12px] text-text-secondary" }
const _hoisted_21 = { class: "text-sm font-bold text-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('general.totalBalance')), 1),
      _createElementVNode("strong", _hoisted_4, _toDisplayString(_ctx.$filters.price(_ctx.totalBalance, _ctx.currency, 2)), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('general.availableBalance')), 1),
      _createElementVNode("strong", _hoisted_7, _toDisplayString(_ctx.$filters.price(_ctx.availableBalance, _ctx.currency, 2)), 1)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('general.marginUsed')), 1),
      _createElementVNode("strong", _hoisted_10, _toDisplayString(_ctx.$filters.price(_ctx.userMarginUsed, _ctx.currency, 2)), 1)
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('general.totalPAndL')), 1),
      _createElementVNode("strong", {
        class: _normalizeClass(["text-sm font-bold", _ctx.plClasses])
      }, _toDisplayString(_ctx.$filters.price(_ctx.userPL, _ctx.currency, 2)), 3)
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t('general.totalPAndL')), 1),
        _createElementVNode("strong", {
          class: _normalizeClass(["text-sm font-bold text-text", _ctx.plClasses])
        }, _toDisplayString(_ctx.$filters.price(_ctx.userPL, _ctx.currency, 2)), 3)
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t('general.totalBalance')), 1),
        _createElementVNode("strong", _hoisted_18, _toDisplayString(_ctx.$filters.price(_ctx.totalBalance, _ctx.currency, 2)), 1)
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$t('general.marginUsed')), 1),
        _createElementVNode("strong", _hoisted_21, _toDisplayString(_ctx.$filters.price(_ctx.userMarginUsed, _ctx.currency, 2)), 1)
      ])
    ])
  ]))
}