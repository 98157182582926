
import { defineComponent } from 'vue'
export default defineComponent({
	name: 'Icon',
	props: {
		name: {
			type: String,
			required: true,
		},
		color: {
			type: String,
			default: 'text',
		},
		size: {
			type: String,
			default: 'base',
		},
	},
	computed: {
		classes: function (): string[] {
			return [
				'icon-' + this.name,
				'text-' + this.color,
				'text-' + this.size,
				'inline-block',
			]
		},
	},
})
