export enum RouteNames {
	NotFound = 'not-found',
	Home = '',
	Dashboard = 'dashboard',
	SignIn = 'sign-in',
	SignUp = 'sign-up',
	LightningSignUp = 'lightning-sign-up',
	ChangePassword = 'change-password',
	ResetPassword = 'reset-password',
	VerifyAccount = 'verify-account',
	Instrument = 'instrument',
	WalletDeposit = 'wallet-deposit',
	WalletDepositBanxa = 'wallet-deposit-banxa',
	WalletWithdraw = 'wallet-withdraw',
	Kyc = 'kyc',
	Portfolio = 'portfolio',
	Trade = 'trade',
	Markets = 'markets',
	Wallet = 'wallet',
	Account = 'account',
	AccountSecurity = 'account-security',
	AccountSettings = 'account-settings',
	AccountSupport = 'account-support',
	AccountChangePwd = 'account-change-password',
	PortfolioMyOrders = 'portfolio-myorders',
	Elements = 'elements',
}

export default RouteNames