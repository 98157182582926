import { UpdateOrderRequestDto } from '@/types/modules/Order'
import { CreateOrderRequestDto, Order } from '@/types'
import { defineStore } from 'pinia'
import axios, { AxiosResponse, AxiosError } from 'axios'
import { IOrderDataStore } from '@/interfaces'
import { OrderStatus } from '@/enums'

export const useOrderDataStore = defineStore({
	id: 'orderData',
	state: () : IOrderDataStore => ({
		orders: [] as Order[],
		PL1D: 0,
		PL30D: 0,
	}),
	getters: {
		getOrderById: (state)  => {
			return (id: string) => state.orders.find((order) => order.orderId === id)
		},
		ordersPL():number{
			return this.filledOrders.reduce((acc:number, order:Order) => acc + order.PL, 0)
		},
		ordersSwap():number{
			return this.filledOrders.reduce((acc:number, order:Order) => acc + order.swap, 0)
		},
		notOpenOrders():Order[]{
			return this.orders.filter((order:Order) => order.status !== OrderStatus.open)
		},
		notClosedOrders():Order[]{
			return this.orders.filter((order:Order) => order.status !== OrderStatus.closed)
		},
		ordersMargin():number{
			return this.notClosedOrders.reduce((acc:number, order:Order) => acc + order.margin, 0)
		},
		filledOrders(): Order[] {
			return this.orders.filter((order:Order) => order.status === OrderStatus.filled)
		},
	},
	actions: {
		updateLocalOrder(order: Order) {
			const index = this.orders.findIndex((o) => o.orderId === order.orderId)
			this.orders.splice(index, 1, order)
		},
		async fetchPortfolio() {
			await axios
				.get('portfolio')
				.then((response: AxiosResponse) => {
					this.orders = response.data.orders
					this.PL1D = response.data.PL1D
					this.PL30D = response.data.PL30D
				})
				.catch((error: AxiosError) => {
					console.error(error)
				})
		},
		async updatePortfolio(orders: Order[]) {
			this.orders = orders
		},
		async updatePortfolioOrder(newOrders: Order[]) {
			newOrders.forEach(newOrder => {
				const orderIndex = this.orders.findIndex(oldOrder => oldOrder.orderId === newOrder.orderId)
				if(orderIndex === -1) return
				this.orders[orderIndex] = { ...newOrder }
			})
		},
		async updateOrder(updateOrderDto: UpdateOrderRequestDto): Promise<AxiosResponse> {
			return await axios
				.put('portfolio/orders', 
					updateOrderDto,
					{
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					},
				)
		},
		async createOrder(createOrderDto: CreateOrderRequestDto): Promise<AxiosResponse> {
			return await axios
				.post(
					'portfolio/orders', 
					createOrderDto,
					{
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					},
				)
		},
		async closeOrder(orderId: string): Promise<AxiosResponse> {
			return await axios
				.delete('portfolio/orders', 
					{
						data: {
							orderId,
						},
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					},
				)
		},
	},
})

