import { InAppMessage } from '@/types'
import { defineStore } from 'pinia'
import axios, { AxiosResponse, AxiosError } from 'axios'
import { useUserDataStore } from './UserDataStore'
import { InAppMessagePosition } from '@/enums'

export const useContentDisplayDataStore = defineStore({
	id: 'contentDisplayData',
	state: () => ({
		contentDisplays: null as InAppMessage[] | null,
		isLoading: false,
	}),
	actions: {
		async fetchContentDisplays(lang: string) {
			const isLoggedIn = useUserDataStore().isLoggedIn
			this.isLoading = true
			await axios
				.get(`in-app-message/${isLoggedIn ? 'authorized' : 'unauthorized'}`, {
					params: {
						lang: lang,
					},
				})
				.then((response: AxiosResponse) => {
					const data = []
					for (const display of response.data) {
						display.content = JSON.parse(display.content)
						data.push(display)
					}
					this.contentDisplays = data
				})
				.catch((error: AxiosError) => {
					this.contentDisplays = []
					console.error(error)
				})
				.finally(() => {
					this.isLoading = false
				})
		},
		async acknowledgeContentDisplay(displayId: string, lang: string) {
			await axios
				.get('in-app-message/acknowledge', {
					params: {
						lang,
						displayId,
					},
				})
				.then((response: AxiosResponse) => {
					const data = []
					for (const display of response.data) {
						display.content = JSON.parse(display.content)
						data.push(display)
					}
					this.contentDisplays = data
				})
				.catch((error: AxiosError) => {
					console.error(error)
				})
		},
		getContentDisplay(position: InAppMessagePosition): InAppMessage | null {
			if (!this.contentDisplays) return null
			return this.contentDisplays.find((display) => display.position === position) || null
		},

	},
})