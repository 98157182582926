
import { defineComponent } from 'vue'
import { RouterView } from 'vue-router'

import PublicTopBar from '@/layouts/appLayout/PublicTopBar.vue'

export default defineComponent({
	name: 'PublicLayout',
	components: {
		RouterView,
		PublicTopBar,
	},
	computed: {
		showPublicTopBar() {
			if (this.$route.meta.layout === 'public') return true
			return false
		},
	},
})
