import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useUserDataStore } from '@/stores/data/UserDataStore'
import beforeEach from './beforeEach'
import afterEach from './afterEach'
import AppLayout from '@/layouts/AppLayout.vue'
import PublicLayout from '@/layouts/PublicLayout.vue'
import Error404 from '@/views/Error404.vue'
import { RouteNames } from '@/enums'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/:pathMatch(.*)*',
		name: RouteNames.NotFound,
		component: Error404,
	},
	{
		path: '/',
		component: PublicLayout,
		children: [
			{
				path: 'sign-in',
				alias: '/',
				name: RouteNames.SignIn,
				props: (route) => ({ redirect: route.redirectedFrom }),
				component: () => import('@/views/Auth/SignInView.vue'),
			},
		],
		beforeEnter: () => {
			const userStore = useUserDataStore()
			if(userStore.isLoggedIn) {
				return {name: RouteNames.Dashboard}
			}
		},
	},
	{
		path: '/',
		component: PublicLayout,
		children: [
			{
				path: '/sign-up',
				name: RouteNames.SignUp,
				component: () => import('@/views/Auth/SignUpView.vue'),
			},
			{
				path: '/lightning-sign-up',
				name: RouteNames.LightningSignUp,
				component: () => import('@/views/Auth/LightningSignUpView.vue'),
			},
			{
				path: '/change-password/:token',
				name: RouteNames.ChangePassword,
				props: true,
				component: () => import('@/views/Auth/ChangePasswordView.vue'),
			},
			{
				path: '/reset-password',
				name: RouteNames.ResetPassword,
				component: () => import('@/views/Auth/ResetPasswordView.vue'),
			},
			{
				path: '/verify-account',
				name: RouteNames.VerifyAccount,
				component: () => import('@/views/Auth/VerifyAccountView.vue'),
			},
			{
				path: '/instrument/:id',
				name: RouteNames.Instrument,
				props: true,
				meta: {layout: 'public'},
				component: () => import('@/views/InstrumentView.vue'),
			},
			{
				path: '/wallet/deposit/:id?',
				name: RouteNames.WalletDeposit,
				meta: {
					protected: true,
				},
				component: () => import('../views/Wallet/DepositView.vue'),
			},
			{
				path: '/wallet/deposit/banxa',
				name: RouteNames.WalletDepositBanxa,
				meta: {
					protected: true,
				},
				component: () => import('../views/Wallet/BanxaView.vue'),
			},
			{
				path: '/wallet/withdraw/:id?',
				name: RouteNames.WalletWithdraw,
				meta: {
					protected: true,
				},
				component: () => import('../views/Wallet/WithdrawView.vue'),
			},
			{
				path: '/kyc',
				name: RouteNames.Kyc,
				meta: {
					protected: true,
				},
				component: () => import('@/views/KYC.vue'),
			},
		],
	},
	{
		path: '/',
		component: AppLayout,
		meta: {
			protected: true,
		},
		children: [
			{
				path: 'dashboard',
				alias: '/',
				name: RouteNames.Dashboard,
				component: () => import('@/views/DashboardView.vue'),
			},
			{
				path: 'portfolio',
				name: RouteNames.Portfolio,
				component: () => import('@/views/PortfolioView.vue'),
			},
			{
				path: 'trade/:id?',
				name: RouteNames.Trade,
				component: () => import('@/views/TradeView.vue'),
			},
			{
				path: 'markets',
				name: RouteNames.Markets,
				component: () => import('@/views/MarketsView.vue'),
			},
			{
				path: 'wallet',
				name: RouteNames.Wallet,
				component: () => import('@/views/WalletView.vue'),
			},
			{
				path: 'settings',
				name: RouteNames.AccountSettings,
				component: () => import('@/views/SettingsView.vue'),
			},
			{
				path: 'account-change-password',
				name: RouteNames.AccountChangePwd,
				component: () => import('@/views/Account/ChangePasswordView.vue'),
			},
			{
				path: 'support',
				name: RouteNames.AccountSupport,
				component: () => import('@/views/SupportView.vue'),
			},
			// {
			// 	path: 'account',
			// 	name: RouteNames.Account,
			// 	component: () => import('@/views/AccountView.vue'),
			// 	children: [
			// 		{
			// 			path: '/security',
			// 			name: RouteNames.AccountSecurity,
			// 			component: () => import(/* webpackChunkName: "account" */ '@/views/Account/SecurityView.vue'),
			// 		},
			// 		{
			// 			path: '/settings',
			// 			name: RouteNames.AccountSettings,
			// 			component: () => import(/* webpackChunkName: "account" */ '@/views/Account/SettingsView.vue'),
			// 		},
			// 		{
			// 			path: '/support',
			// 			name: RouteNames.AccountSupport,
			// 			component: () => import(/* webpackChunkName: "account" */ '@/views/Account/SupportView.vue'),
			// 		},
			// 	],
			// },
			{
				path: '/portfolio/myorders',
				name: RouteNames.PortfolioMyOrders,
				component: () => import('@/views/portfolio/MyOrdersView.vue'),
			},
			{
				path: '/elements',
				name: RouteNames.Elements,
				component: () => import('@/views/ElementsView.vue'),
			},
		],
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes: routes,
})

router.beforeEach(beforeEach)
router.afterEach(afterEach)

export default router
