
import { defineComponent } from 'vue'
import { mapState, mapActions } from 'pinia'
import { OnClickOutside } from '@vueuse/components'
import { RouteNames, GtmCategory, GtmEvents } from '@/enums'
import { gtmPush } from '@/helpers/gtm'

import Button from '@/components/General/Button.vue'
import Icon from '@/components/General/Icon.vue'
import FadeTransition from '@/components/Transitions/FadeTransition.vue'

import { useAccountViewStore } from '@/stores/views/AccountViewStore'
import { useUserDataStore } from '@/stores/data/UserDataStore'

export default defineComponent({
	name: 'TopBar',
	components: {
		Button,
		Icon,
		FadeTransition,
		OnClickOutside,
	},
	setup() {
		return {
			accountStore: useAccountViewStore(),
			userStore: useUserDataStore(),
		}
	},
	data() {
		return {
			accountOpened: false,
			menu: [
				{
					name: 'settings',
					icon: 'account-full',
					link: RouteNames.AccountSettings,
				},
				{
					name: 'support',
					icon: 'support',
					link: RouteNames.AccountSupport,
				},
			],
			RouteNames,
			GtmCategory,
			GtmEvents,
			gtmPush,
		}
	},
	computed: {
		userIDText(): string {
			return this.$t('general.userId').replace(' ', '&nbsp;')+':'
		},
	},
	methods: {
		selectDropdownOption() {
			gtmPush(GtmCategory.GENERAL, GtmEvents.ACCOUNT_SETTINGS)
			this.accountOpened = false
		},
		submit() {
			this.accountOpened = false
			gtmPush(GtmCategory.GENERAL, GtmEvents.LOGOUT)
			this.userStore.logout()
			this.$router.push({ name: RouteNames.SignIn })
		},
	},
})
