import { defineStore } from 'pinia'
import { IMetaDataStore } from '@/interfaces'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { Button, FilterItem } from '@/types'
import { Currency, Sectors, InstrumentType } from '@/enums'

export const useMetaDataStore = defineStore({
	id: 'metaData',

	state: (): IMetaDataStore => ({
		baseCurrency: null,
		limits: {
			tradingConfig: {
				baseCurrency: {
					btc: false,
					fiat: false,
				},
				baseAssetMinLeverage: 0,
				baseAssetMaxLeverage: 0,
				replicatedAssetAllowedLeverage: [],
				minMargin: 0,
				maxMargin: 0,
				feeConfig: {
					swapTimeUTC: '',
					openFee: 0,
					minOpenFee: 0,
					swapRateBuy: 0,
					swapRateSell: 0,
				},
			},
		},
		instruments: {
			totalInstruments: 0,
			instrumentTypes: {
				ETF: 0,
				CRYPTO: 0,
				COMMODITY: 0,
				STOCK: 0,
				INDEX: 0,
			},
		},
		categories: [],
		countries: [],
		sectors: Object.values(Sectors),
		currencies: Object.values(Currency),
		documents: [],
		instrumentTypes: Object.values(InstrumentType),
	}),

	actions: {
		async fetchMetaData() {
			await axios
				.get('meta')
				.then((response: AxiosResponse) => {
					this.baseCurrency = response.data.baseCurrency as Currency

					const isBtc = response.data.baseCurrency === 'BTC'

					this.limits.tradingConfig = response.data.limits.tradingConfig

					// I am extracting base currency to the tradingConfig since we implemented formulas like that
					this.limits.tradingConfig.baseCurrency = {
						btc: isBtc,
						fiat: !isBtc,
					}

					if (isBtc) {
						this.limits.tradingConfig.minMargin = this.limits.tradingConfig.minMargin * 100_000_000
						this.limits.tradingConfig.maxMargin = this.limits.tradingConfig.maxMargin * 100_000_000
					}

					this.instruments.totalInstruments = response.data.instruments.totalInstruments
					this.instruments.instrumentTypes = response.data.instruments.instrumentTypes
					this.categories = response.data.categories
					if (response.data?.sectors) this.sectors = response.data.sectors
					if (response.data?.currencies) this.currencies = response.data.currencies
					if (response.data?.instrumentTypes) this.instrumentTypes = response.data.instrumentTypes
				})
				.catch((error: AxiosError) => {
					console.error(error)
				})

			this.countries = [
				{ id: 0, key: 'arg' },
				{ id: 1, key: 'aus' },
				{ id: 2, key: 'aut' },
				{ id: 3, key: 'bhr' },
				{ id: 4, key: 'blr' },
				{ id: 5, key: 'bra' },
				{ id: 6, key: 'can' },
				{ id: 7, key: 'chl' },
				{ id: 8, key: 'chn' },
				{ id: 9, key: 'col' },
				{ id: 10, key: 'hrv' },
				{ id: 11, key: 'cze' },
				{ id: 12, key: 'dnk' },
				{ id: 13, key: 'ecu' },
				{ id: 21, key: 'isl' },
				{ id: 22, key: 'ind' },
				{ id: 23, key: 'idn' },
				{ id: 24, key: 'irl' },
				{ id: 25, key: 'isr' },
				{ id: 26, key: 'ita' },
				{ id: 27, key: 'jpn' },
				{ id: 28, key: 'jor' },
				{ id: 29, key: 'kaz' },
				{ id: 30, key: 'kwt' },
				{ id: 31, key: 'mys' },
				{ id: 32, key: 'mex' },
				{ id: 33, key: 'nld' },
				{ id: 34, key: 'nzl' },
				{ id: 35, key: 'nor' },
				{ id: 36, key: 'omn' },
				{ id: 37, key: 'per' },
				{ id: 38, key: 'phl' },
				{ id: 39, key: 'pol' },
				{ id: 40, key: 'prt' },
				{ id: 41, key: 'qat' },
				{ id: 42, key: 'rou' },
				{ id: 43, key: 'rus' },
				{ id: 44, key: 'sau' },
				{ id: 45, key: 'srb' },
				{ id: 46, key: 'sgp' },
				{ id: 47, key: 'svk' },
				{ id: 48, key: 'svn' },
				{ id: 49, key: 'zaf' },
				{ id: 50, key: 'kor' },
				{ id: 51, key: 'esp' },
				{ id: 52, key: 'swe' },
				{ id: 53, key: 'che' },
				{ id: 54, key: 'twn' },
				{ id: 55, key: 'tha' },
				{ id: 56, key: 'tur' },
				{ id: 57, key: 'ukr' },
				{ id: 58, key: 'are' },
				{ id: 59, key: 'gbr' },
				{ id: 60, key: 'usa' },
				{ id: 61, key: 'vnm' },
			]

			this.documents = [
				{ id: 1, key: 'idCard' },
				{ id: 2, key: 'passport' },
				{ id: 3, key: 'drivingLicense' },
			]
		},
	},

	getters: {
		sectorsList: (state): Array<string> => {
			return ['all', ...state.sectors]
		},
		currenciesList: (state): Array<string> => {
			return ['all', ...state.currencies]
		},
		instrumentTypesList: (state): Array<string> => {
			return ['all', ...state.instrumentTypes]
		},
		assetAllowedLeverageInButtons: (state): Button[] => {
			return state.limits.tradingConfig.replicatedAssetAllowedLeverage.map((leverage) => {
				return {
					value: leverage,
					text: `${leverage}x`,
				}
			})
		},
		instrumentCategories: (state): FilterItem[] => {
			const metaInstrumentTypes = Object.keys(state.instruments.instrumentTypes).map((key) => {
				return {
					id: key,
					name: key,
				}
			})

			return [{ name: 'All', id: 'all' }, ...metaInstrumentTypes]
		},
	},
})
