enum Currency {
  CZK = 'CZK',
  EUR = 'EUR',
  BTC = 'BTC',
  SAT = 'SAT',
  USD = 'USD',
  JPY = 'JPY',
  CAD = 'CAD',
  CHF = 'CHF',
  TRY = 'TRY',
  GBP = 'GBP',
  HKD = 'HKD',
  MXN = 'MXN',
  CNY = 'CNY',
  SGD = 'SGD',
  NOK = 'NOK',
  SEK = 'SEK',
  NZD = 'NZD',
  AUD = 'AUD',
  CPO = 'CPO',
}

enum CurrencySymbol {
  CZK = ' Kč',
  EUR = '€',
  USD = '$',
  BTC = 'BTC',
  SAT = 'SAT',
  JPY = '¥',
  CAD = 'C$',
  CHF = 'Fr',
  TRY = '₺',
  GBP = '£',
  HKD = 'HK$',
  MXN = 'MX$',
  CNY = '¥',
  SGD = 'S$',
  NOK = 'NKr',
  SEK = 'kr',
  NZD = 'NZ$',
  AUD = 'A$',
  CPO = 'COL$',
}

export { Currency, CurrencySymbol }
