import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "max-h-screen overflow-auto w-full flex flex-col" }
const _hoisted_2 = { class: "flex-grow w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PublicTopBar = _resolveComponent("PublicTopBar")!
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showPublicTopBar)
      ? (_openBlock(), _createBlock(_component_PublicTopBar, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("main", {
      class: _normalizeClass(["w-full place-self-center flex flex-row flex-grow relative", {
				'px-6': _ctx.showPublicTopBar,
				'px-0': !_ctx.showPublicTopBar,
				'py-8': _ctx.showPublicTopBar,
				'py-0': !_ctx.showPublicTopBar,
			}])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            _createVNode(_component_RouterView)
          ]),
          _: 1
        })
      ])
    ], 2)
  ]))
}