
import { Vue3Lottie } from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import { defineComponent, onMounted, inject, ref } from 'vue'

export default defineComponent({
	name: 'LoadingApp',
	components: {
		Vue3Lottie,
	},
	props: {
		width: {
			type: Number,
			default: 200,
		},
		height: {
			type: Number,
			default: 200,
		},
	},
	setup() {
		const brand = inject('brand')
		const animationJSON = ref(null)
		onMounted(async () => {
			try {
				const brandData = await import(
					`@/assets/data/animations/${brand}/loading-animation.json`
				)
				animationJSON.value = brandData.default
			} catch (error) {
				console.error(error)
			}
		})
		return {
			brand,
			animationJSON,
		}
	},

	data() {
		return {
			frame: 0,
			isVisible: false,
			text: '',
		}
	},
})
