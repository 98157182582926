
import { defineComponent } from 'vue'
import { Currency } from '@/enums'

import { useUserDataStore } from '@/stores/data/UserDataStore'
import {useOrderDataStore} from '@/stores/data/OrderDataStore'

export default defineComponent({
	name: 'Summary',
	setup() {
		return {
			userStore: useUserDataStore(),
			orderStore: useOrderDataStore(),
		}
	},
	computed: {

		currency(): Currency {
			return this.userStore.userCurrency
		},
		balance(): number {
			return this.userStore.userBalance
		},
		userMarginUsed(): number {
			return this.orderStore.ordersMargin
		},
		userPL(): number {
			const totalPL = this.orderStore.ordersPL + this.orderStore.ordersSwap
			return this.currency == Currency.SAT ? this.$filters.fromBtcToSat(totalPL) : totalPL
		},
		// For total balance we need to add user balance and used margin
		totalBalance(): number {
			return this.balance + this.userMarginUsed + this.userPL + this.userStore.userHolds
		},
		// In fact, user balance is available balance, without used margin
		availableBalance(): number {
			return this.balance
		},
		plClasses(): string {
			if(this.userPL === 0) return 'text-text'
			return this.userPL > 0 ? 'text-success' : 'text-error'
		},
	},
})
