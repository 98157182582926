import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col items-center relative"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Vue3Lottie = _resolveComponent("Vue3Lottie")!

  return (_ctx.animationJSON)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Vue3Lottie, {
          "animation-data": _ctx.animationJSON,
          height: _ctx.height,
          width: _ctx.width
        }, null, 8, ["animation-data", "height", "width"])
      ]))
    : _createCommentVNode("", true)
}