import { Currency, CurrencySymbol } from '@/enums'

const filters = {
	numberSanitizer(value: string) {
		return value.replace(/[^\d.-]+/g, '')
	},
	change(value: number | string): string {
		value = value.toString()

		return (value[0] === '-')
			? `- ${value.slice(1)}`
			: `+ ${value}`
	},
	percent(value: number, displayChange?: boolean) {
		const result = Number(value/100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })
		if (displayChange) {
			return this.change(result)
		} 
		return result
	},
	percentageChange(from: number, to: number): number {
		const change = ((to - from) / from ) * 100
		if (change === Infinity) {
			return 100
		}
		if (change === -Infinity) {
			return -100
		}
		if (isNaN(change)) {
			return 0
		}
		//exception increase/decrease check for percent 
		if(from < to && change < 0 || from > to && change > 0) return change * -1
		
		return change
	},
	btc(value: number, displayChange?: boolean) {
		const result = `${value} BTC`
		if (displayChange) {
			return this.change(result)
		}
		return result
	},
	priceFormat(value: number | string, thousandsDeliminer = ','): string {
		const parts = value.toString().split('.')
		const numberPart = parts[0]
		const decimalPart = parts[1]
		const thousands = /\B(?=(\d{3})+(?!\d))/g
		return numberPart.replace(thousands, thousandsDeliminer) + (decimalPart ? '.' + decimalPart : '')
	},
	price(input: number, currency: Currency, precision: number, thousandsDeliminer = ','): string {
		switch(currency) {
		case(Currency.CZK): 
			return this.priceFormat(input.toFixed(precision), thousandsDeliminer) + CurrencySymbol.CZK
		case(Currency.EUR):
			return CurrencySymbol.EUR + ' ' + this.priceFormat(input.toFixed(precision), thousandsDeliminer)
		case(Currency.USD):
			return CurrencySymbol.USD + ' ' + this.priceFormat(input.toFixed(precision), thousandsDeliminer)
		case(Currency.BTC):
			return this.priceFormat(input.toFixed(precision), thousandsDeliminer) + ' ' + CurrencySymbol.BTC
		case(Currency.SAT):
			return this.priceFormat(input.toFixed(precision), thousandsDeliminer) + ' ' + CurrencySymbol.SAT
		default:
			if(CurrencySymbol[currency]){
				return CurrencySymbol[currency] + ' ' + this.priceFormat(input.toFixed(precision), thousandsDeliminer)
			}
			return this.priceFormat(input.toFixed(precision), thousandsDeliminer) + ' ' + currency
		}
	},
	priceSymbol(currency: Currency): string {
		switch(currency) {
		case(Currency.CZK):
			return CurrencySymbol.CZK
		case(Currency.EUR):
			return CurrencySymbol.EUR
		case(Currency.USD):
			return CurrencySymbol.USD
		case(Currency.BTC):
			return CurrencySymbol.BTC
		case(Currency.SAT):
			return CurrencySymbol.SAT
		default:
			return CurrencySymbol.USD
		}
	},
	sat(value: number, displayChange?: boolean) {
		const result = `${value.toFixed(0)} SAT`
		if (displayChange) {
			return this.change(result)
		}
		return result
	},
	usd(value: number, displayChange?: boolean) {
		const result = value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
		if (displayChange) {
			return this.change(result)
		}
		return result
	},
	eur(value: number, displayChange?: boolean) {
		const result =  value.toLocaleString('en-US', { style: 'currency', currency: 'EUR' })
		if (displayChange) {
			return this.change(result)
		}
		return result
	},
	fromBtcToSat(value: number) {
		return value * 100000000
	},
	fromSatToBtc(value: number) {
		return value / 100000000
	},
	fromTimestampToShortDate(timestamp: number) {
		const dateObj = new Date(timestamp)
		return dateObj.toLocaleString('en-GB', { timeZone: 'UTC' })
	},
	lang2Flag(lang: string): string {
		switch(lang){
		case 'en':
			return 'gbr'
		case 'cs':
			return 'cze'
		case 'de':
			return 'deu'
		case 'es':
			return 'esp'
		case 'es-co':
			return 'col'
		case 'pl':
			return 'pol'
		case 'sk':
			return 'svk'
		case 'sl':
			return 'svn'
		default:
			return 'gbr'
		}
	},
	dateTime(input: number): string {
		const date = new Date(input)
		const day = date.toLocaleString('en-US', { day: '2-digit' })
		const month = date.toLocaleString('en-US', { month: 'short' })
		const year = date.getFullYear()
		const hour = date.getHours()
		const hourStr = String(hour).padStart(2, '0')
		const minute = String(date.getMinutes()).padStart(2, '0')
		return `${day} ${month} ${year} / ${hourStr}:${minute}`
	},
}

export default filters
